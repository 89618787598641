import React, { FunctionComponent } from 'react';
import { Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const FooterMenu:FunctionComponent<{}> = () => {
  return (
    <Nav id="footer-dashboard" className="justify-content-md-center ">
      <Nav.Item>
        <Nav.Link 
          target="_blank" 
          rel="noopener noreferrer" 
          href="https://101obex.com/termsconditions">Terms and Conditions</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link 
          target="_blank" rel="noopener noreferrer" 
          href="https://101obex.com/privacypolicy">Privacy Policy</Nav.Link>
      </Nav.Item>
    </Nav>
  );
}


export default FooterMenu;