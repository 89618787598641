import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

type SubsSuccessProps = {
  userInfo: any
}

const SubsSuccessContent:FunctionComponent<SubsSuccessProps> = (props) => {

  const { userInfo } = props;

  return (
    <Row>
      <Col md="9" className="mx-auto">
      <Row>
          <Col className="mx-auto">
            <Row>
              <Col>
                <h1 className="mb-3">Account information</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="product-detail-card obex-card project-access-card mb-3">
                  <Card.Body className="p-3">
                    <Form className="obex-form">
                    <Form.Row className="mb-3">
                        <Form.Label column md="2" className="obex-form-label">
                          Email
                        </Form.Label>
                        <Col>
                          <InputGroup className="obex-input-group mb-3">
                            <FormControl
                              placeholder={userInfo.email}
                              aria-describedby={userInfo.email}
                              value={userInfo.email}
                              disabled
                            />
                            <InputGroup.Append>
                              <Button className="text-black" value="id_project">
                                <FontAwesomeIcon icon={faCopy}/>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Row>                      
                      <Form.Row className="mb-3">
                        <Form.Label column md="2" className="obex-form-label">
                          Password
                        </Form.Label>
                        <Col>
                          <InputGroup className="obex-input-group mb-3">
                            <FormControl
                              placeholder={userInfo.password}
                              aria-describedby={userInfo.password}
                              value={userInfo.password}
                              disabled
                            />
                            <InputGroup.Append>
                              <Button className="text-black" style={{ backgroundColor:'#e9ecef' }}>
                                <FontAwesomeIcon icon={faCopy}/>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                      </Form.Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        {userInfo.projects.production && 
          <Row>
            <Col className="mx-auto">
              <Row>
                <Col>
                  <h1 className="mb-3">Project Production information</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="product-detail-card obex-card project-access-card mb-3">
                    <Card.Body className="p-3">
                      <Form className="obex-form">
                      <Form.Row className="mb-3">
                          <Form.Label column md="2" className="obex-form-label">
                            ID
                          </Form.Label>
                          <Col>
                            <InputGroup className="obex-input-group mb-3">
                              <FormControl
                                placeholder={userInfo.projects.production.name}
                                aria-describedby={userInfo.projects.production.name}
                                value={userInfo.projects.production.name}
                                disabled
                              />
                              <InputGroup.Append>
                                <Button className="text-black" value="id_project">
                                  <FontAwesomeIcon icon={faCopy}/>
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                        </Form.Row>                      
                        <Form.Row className="mb-3">
                          <Form.Label column md="2" className="obex-form-label">
                            API Key
                          </Form.Label>
                          <Col>
                            <InputGroup className="obex-input-group mb-3">
                              <FormControl
                                placeholder={userInfo.projectProduction.apikey}
                                aria-describedby={userInfo.projectProduction.apikey}
                                value={userInfo.projectProduction.apikey}
                                disabled
                              />
                              <InputGroup.Append>
                                <Button className="text-black" style={{ backgroundColor:'#e9ecef' }}>
                                  <FontAwesomeIcon icon={faCopy}/>
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                        </Form.Row>
                        { userInfo.projects.production.chiperKey &&
                          <Form.Row className="mb-3">
                            <Form.Label column md="2" className="obex-form-label">
                              Chiper Key
                            </Form.Label>
                            <Col>
                              <InputGroup className="obex-input-group mb-3">
                                <FormControl
                                  placeholder={userInfo.projects.production.chiperKey}
                                  aria-describedby={userInfo.projects.production.chiperKey}
                                  value={userInfo.projects.production.chiperKey}
                                  disabled
                                />
                                <InputGroup.Append>
                                  <Button className="text-black" style={{ backgroundColor:'#e9ecef' }}>
                                    <FontAwesomeIcon icon={faCopy}/>
                                  </Button>
                                </InputGroup.Append>
                              </InputGroup>
                            </Col>
                          </Form.Row>
                        }
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        {userInfo.projects.staging &&
          <Row>
            <Col className="mx-auto">
              <Row>
                <Col>
                  <h1 className="mb-3">Project Staging information</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="product-detail-card obex-card project-access-card mb-3">
                    <Card.Body className="p-3">
                      <Form className="obex-form">
                      <Form.Row className="mb-3">
                          <Form.Label column md="2" className="obex-form-label">
                            ID
                          </Form.Label>
                          <Col>
                            <InputGroup className="obex-input-group mb-3">
                              <FormControl
                                placeholder={userInfo.projects.staging.name}
                                aria-describedby={userInfo.projects.staging.name}
                                value={userInfo.projects.staging.name}
                                disabled
                              />
                              <InputGroup.Append>
                                <Button className="text-black" value="id_project">
                                  <FontAwesomeIcon icon={faCopy}/>
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                        </Form.Row>                      
                        <Form.Row className="mb-3">
                          <Form.Label column md="2" className="obex-form-label">
                            API Key
                          </Form.Label>
                          <Col>
                            <InputGroup className="obex-input-group mb-3">
                              <FormControl
                                placeholder={userInfo.projects.staging.apikey}
                                aria-describedby={userInfo.projects.staging.apikey}
                                value={userInfo.projects.staging.apikey}
                                disabled
                              />
                              <InputGroup.Append>
                                <Button className="text-black" style={{ backgroundColor:'#e9ecef' }}>
                                  <FontAwesomeIcon icon={faCopy}/>
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                        </Form.Row>
                        { userInfo.projects.staging.chiperKey &&
                          <Form.Row className="mb-3">
                            <Form.Label column md="2" className="obex-form-label">
                              Chiper Key
                            </Form.Label>
                            <Col>
                              <InputGroup className="obex-input-group mb-3">
                                <FormControl
                                  placeholder={userInfo.projects.staging.chiperKey}
                                  aria-describedby={userInfo.projects.staging.chiperKey}
                                  value={userInfo.projects.staging.chiperKey}
                                  disabled
                                />
                                <InputGroup.Append>
                                  <Button className="text-black" style={{ backgroundColor:'#e9ecef' }}>
                                    <FontAwesomeIcon icon={faCopy}/>
                                  </Button>
                                </InputGroup.Append>
                              </InputGroup>
                            </Col>
                          </Form.Row>
                        }
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      </Col>
    </Row>
  )
}

export default SubsSuccessContent;