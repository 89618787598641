import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import React, { FunctionComponent } from 'react';
import '../../../assets/css/baseLayout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderMenu from './HeaderMenu';
import ApiSideBar from './ApiSideBar';
import Footer from '../FooterLayout/FooterMenu';
import CookieMessage from '../../Modals/CookieMessage';
import { useState } from 'react';
import ReactGA from 'react-ga';

type BaseProps = {
  sider: any,
  active?: string,
  children: any
};

const BaseLayout: FunctionComponent<BaseProps> = ({ sider, active, children }: BaseProps) => {

  const [thankyou, setThankyou] = useState(false);
  const [goodFeedback, setGood] = useState(true);
  const [badFeedback, setBad] = useState(true);
  const [StyleGood, setStyleGood] = useState({ backgroundColor: '#818881' });
  const [StyleBad, setStyleBad] = useState({ backgroundColor: '#818881' });
  const [hooverGood, sethooverGood] = useState(false);
  const [hooverBad, sethooverBad] = useState(false);

  const BadFeedback = () => {
    setGood(false);
    setThankyou(true);
    setBad(false);
    ReactGA.event({
      category: 'Feedback',
      action: 'Bad',
      label: (window.location.pathname + window.location.search)
    });

  }
  const GoodFeedback = () => {
    setBad(false);
    setThankyou(true);
    setGood(false);
    ReactGA.event({
      category: 'Feedback',
      action: 'Good',
      label: (window.location.pathname + window.location.search)
    });
  }

  const toggleHooverGood = () => {
    if (goodFeedback && badFeedback) {
      if (hooverGood) { setStyleGood({ backgroundColor: '#818881' }); sethooverGood(false); }
      else { setStyleGood({ backgroundColor: '#3e413e' }); sethooverGood(true); }
    }
  }
  const toggleHooverBad = () => {
    if (goodFeedback && badFeedback) {
      if (hooverBad) { setStyleBad({ backgroundColor: '#818881' }); sethooverBad(false); }
      else { setStyleBad({ backgroundColor: '#3e413e' }); sethooverBad(true); }
    }
  }

  const cambio = () => {

    setBad(true);
    setGood(true);
    setThankyou(false);
    setStyleGood({ backgroundColor: '#818881' });
    setStyleBad({ backgroundColor: '#818881' });
    sethooverGood(false);
    sethooverBad(false);

  }


  return (
    <Container fluid className="">
      <HeaderMenu />
      <Row id="content-page">
        <ApiSideBar sider={sider} active={active} cambio={cambio} />
        <Col id="content" className="pt-4 pl-4 pr-4 d-flex flex-column min-vh-100">
          {children}
          <Alert key={1} variant='success'>
            <span className="feedback-text">Was this page helpful? </span>
            <Button style={StyleGood} onMouseEnter={toggleHooverGood} onMouseLeave={toggleHooverGood} disabled={!goodFeedback} onClick={() => { GoodFeedback() }} className="button-feedback">Yes</Button>
            <Button style={StyleBad} onMouseEnter={toggleHooverBad} onMouseLeave={toggleHooverBad} disabled={!badFeedback} onClick={() => { BadFeedback() }} className="button-bad-feedback">No</Button>
            {thankyou && <span className="thankyou-text">Thank You for your Feedback!</span>}
          </Alert>
          <div className="border-top mx-3 mt-3 mb-5 mt-auto"></div>
          <Row className="justify-content-md-center">
            <Col md={{ span: 10 }}>
              <Footer />
            </Col>
          </Row>
        </Col>
      </Row>
      <CookieMessage />
    </Container>
  )
}

export default BaseLayout;