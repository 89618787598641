import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import Logo from '../../../assets/img/logodeveloper-pequeño.png';
import { Row, Col, Dropdown, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import SessionExpired from '../../Modals/sessionExpired';
import InactivityModal from '../../Modals/inactivityModal';
import Cookies from 'universal-cookie';
import SessionService from '../../../services/SessionsService';
import ReactGA from 'react-ga';
import AddOrganizationModal from '../../Modals/addOrganizationModal';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';

const DevHeader:FunctionComponent<{}> = () => {

  const history = useHistory();

  const [isLoggedIn, setLogged] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [inactivity, setInactivity] = useState(false);
  const [developer, setDeveloper] = useState(false);
  const [usersuscriptor, setusersuscriptor] = useState(true);
  const [client, setClient] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [projects, setProjects] = useState([]);

  const [nombreOrganizacion, setNameOrg] = useState('');

  const cookies = new Cookies();
  ReactGA.initialize('UA-170480071-2');
  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SessionService.logout();
    window.location.replace('/login');

  }

  const getProjects = async () => {
    try {
      setProjects([]);


      // console.log(`CONSULTANDO ORGANIZACIONES PARA DEVELOPER ${SessionService.DEV_ID}`);
      const projects = (
        await ObexRequestHandler.get(`/my_organizations?dev_id=${SessionService.DEV_ID}`, {}, true)
        ).data || [];

        //console.log(projects);
      setProjects(projects);
      
      if (SessionService.ORGANIZATION_NAME == null || SessionService.ORGANIZATION_NAME == ''){

      if (projects.length>0 /* SessionService.ORGANIZATION_NAME == null || true*/) {
        setNameOrg(projects[0]['name']);
        SessionService.ORGANIZATION_NAME = projects[0]['name'];
        SessionService.ORGANIZATION = projects[0]['id'];
      } else {
        setNameOrg(SessionService.ORGANIZATION_NAME);
      }

      SessionService.eventEmitter.emit('scream', 'Bob');
    } else {
      setNameOrg(SessionService.ORGANIZATION_NAME);
    }
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }


  }


  useEffect(() => {
    // projects.map(x => console.log(x))
    
    // projects.map(x => console.log(x))
    ReactGA.pageview(window.location.pathname + window.location.search);

    const user = cookies.get('101Obex');
    setDeveloper(SessionService.Developer);
    setusersuscriptor(SessionService.User);
    setClient(SessionService.Client);
    if (user){
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) 
      {
        SessionService.renewSession();
      }
      setLogged(true);
      getProjects();
    }
  }, [])

  // Como este componente siempre está presente ponemos aquí el listener del inactivityEvent para que lancé el modal
  document.addEventListener('inactivityEvent', (e) => {

    e.preventDefault();
    e.stopPropagation();
    setInactivity(true);
  }, false);

  // Como este componente siempre está presente ponemos aquí el listener del sessionExpiredEvent para que lancé el modal
  document.addEventListener('sessionExpiredEvent', (e) => {

    if (sessionExpired) return;
    e.preventDefault();
    e.stopPropagation();
    setSessionExpired(true);
  }, false);

const setName = (orga) =>{

console.log(orga.name);

setNameOrg(orga.name);
SessionService.ORGANIZATION_NAME = orga.name;
SessionService.ORGANIZATION = orga.id;
history.push('/devdashboard');
SessionService.eventEmitter.emit('scream', 'Bob');
return orga.name;
}



  const renewSession = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      console.log('Pulsado RENEW')
      await SessionService.renewSession();
      setInactivity(false);
    } catch(error) {
      console.error(error);
    }
  }
  const handleModal = () => {
    
    setShowModal(!showModal);
  };

  return (
    <Fragment>
      <SessionExpired show={sessionExpired} handleHide={handleLogout}/>
      <InactivityModal show={inactivity /* && !sessionExpired*/} renewSession={renewSession} closeSession={handleLogout}/>

      <Row style={{ justifyContent: 'center' }} className={'py-0 fixed-top px-2'} id="header">

      <Row style={{ width:'1378px' }}>
          <Col md={3} className={'pl-3 my-auto'}>
              <a href="/">
                <img className="logo" src={Logo}/>
              </a>
          </Col>
          <Col className="my-auto">
            <Row className="menu-header">
              <Col><a className="link link-header" onClick={() => history.push('/docs/Currencies/Currencies_FX_Exchange_EN')} style={{ textAlign: 'center' }}>Docs</a></Col>
              <Col><a className="link link-header" onClick={() => history.push('/apis/login/Accesos_EN')}style={{ textAlign: 'center' }}>APIs</a></Col>
              <Col><a target="_blank" rel="noopener noreferrer" href="https://status.101obex.com/" className="link link-header" style={{ textAlign: 'center' }}>Status</a></Col>
              {isLoggedIn ?
              //     <Dropdown>
              //     <Dropdown.Toggle className="button-support" >Community Support</Dropdown.Toggle>
              //     <Dropdown.Menu>
              //       <Dropdown.Item onClick={() => (window.location.replace('https://101obexworkspace.slack.com/'))}>Technical Support</Dropdown.Item>
              //       
              //     </Dropdown.Menu>{' '}
              //   </Dropdown>
                <Col><a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header" style={{ textAlign: 'center' }}>Community Support</a></Col>
                :
                <Col><a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header" style={{ textAlign: 'center' }}>Community Support</a></Col>
                
              }
              
            </Row>
          </Col>

          {isLoggedIn ?
                   <Dropdown className='my-auto'>
                   <Dropdown.Toggle className="organizations" >{nombreOrganizacion}</Dropdown.Toggle>
                   <Dropdown.Menu>

                   {projects.map(x =>{
                        return  (<Dropdown.Item key={x.id} onClick={()=>{setName(x);
                        }}>{x.name}</Dropdown.Item>);
                        })}

                   </Dropdown.Menu>{' '}
                 </Dropdown>
                
                :
               <span></span>
                
              }

          {isLoggedIn ? 
            <Col md={1} className="user text-right my-auto">
              <Dropdown className="mr-1">
              <Dropdown.Toggle className="link-user" id="obex-user-menu">{/*<FontAwesomeIcon icon={faUserCircle} size="2x"/>*/}<div style={{ height:'30px' }}><Image id='AvatarImageHeader' style={{ height:'30px' }} src={SessionService.imageContent}/></div></Dropdown.Toggle>
                <Dropdown.Menu>
                  { usersuscriptor && <Dropdown.Item onClick={
                    
                    () => {
                      SessionService.ORGANIZATION_NAME = null;
                      history.push('/dashboard');
                  }}>Administrator Dashboard</Dropdown.Item>}
                  { developer && <Dropdown.Item onClick={() => {
                    
                    history.push('/devdashboard');
                  }}><h5>Developer Dashboard</h5></Dropdown.Item>}
                  { client && <Dropdown.Item onClick={() => {
                    SessionService.ORGANIZATION_NAME = null;
                    history.push('/clientdashboard')
                    
                    }}>Client Dashboard</Dropdown.Item>}
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>{' '}
              </Dropdown>
            </Col>:
            <Col md={2} className="login text-right my-auto">
              <a href="/login" className="link-login">Log into Dashboard</a>
            </Col>
          }
          {/* <Col md={1}>
            <SelectLanguage/> 
          </Col>*/}
          <></>
      </Row>

      </Row>

      {showModal && <AddOrganizationModal show={showModal} handleClose={handleModal}/>}

    </Fragment>
  );
}

export default DevHeader;