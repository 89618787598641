// @ts-nocheck
import React, { FunctionComponent, useEffect, useState} from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DevDashboardLayout';
import DashRouters from '../../configs/routers/dev-dashboard-routers.json';
import { Row, Col, Card, Form, Button, Spinner, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';
import JSONPretty from 'react-json-pretty';

import autocomplete from '../../services/autocomplete';


const DevSandbox:FunctionComponent<{}> = () => {
  
  const [method, setMethod] = useState('GET');
  const [endpoint, setEndpoint] = useState('');
  const [parameters, setParameters] = useState([]);
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [signature, setSignature] = useState('');  

  const buildProperParams = (params) => {
    const formatParams = {};
    params.forEach(p => {
      const { key, value } = p;
      formatParams[key] = value;
    })
    return formatParams;
  }

  /**
   * Recorre el json de parametros y genera una cadena de texto
   * @param {*} parameters
   */
  const joinParameters = (parameters) => {
    if (!Object.keys(parameters).length) return '';
    let first = true;
    let joinned = '';
    for (const key of Object.keys(parameters)) {
      const value = parameters[key];
      joinned += first ? `${key}=${value}` : `&${key}=${value}`;
      first = false;
    }
    return joinned;
  }

  /**
   * Genera la firma
   * @param {*} rawBody
   * @param {*} appSecret
   */
  const sha1HmacDigest = (rawBody, appSecret) => {
    const digest = CryptoJS.HmacSHA1(rawBody, appSecret);
    return digest.toString();
  }

  const createSign = () => {
    const parametersWithoutSign = parameters.filter(p => p.key !== 'firma'); // quitamos la firma en caso de que exista de los parametros para que no la "firme"
    const formattedParameters = buildProperParams(parametersWithoutSign);
    const joinnedParams = joinParameters(formattedParameters);
    const encodedParams = encodeURI(joinnedParams);
    const sign = sha1HmacDigest(encodedParams, signature);
    const np = [...parameters];
    const signExists = Object.keys((np.find(p => p.key === 'firma') || {})).length > 0;
    if (signExists) {
      const index = np.findIndex(p => p.key === 'firma');
      np.splice(index, 1);
      np.push({ key: 'firma', value: sign });
    } else {
      np.push({ key: 'firma', value: sign });
    }
    setParameters(np);
  }

  const makeRequest = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      const formatParameters = buildProperParams(parameters);
      const payload = {
        method,
        endpoint,
        parameters: formatParameters
      };
      const result = await ObexRequestsHandler.post('/sandbox_request', payload);
      const { data, success, message } = result;
      if (!success) throw { message };
      setResult(JSON.stringify(data));
    } catch (error) {
      console.error('ERROR FETCHING LOGS ', error);
      try {
        setResult(error.message || error);
      } catch (error) {
        console.error('ERROR SETTING ERROR MESSAGE ', error)
        setResult(error);
      }
    }
    setLoading(false);
  }

  const handleMethod = (value) => {
    setMethod(value)
  }

  const handleSign = (e) => {
    const { value } = e.target;
    setSignature(value);
  }

  const addParameter = (e) => {
    const np = [...parameters];
    np.push({ key: '', val: '' });
    setParameters(np);
  }

  const removeParameter = (index) => {
    const np = [...parameters];
    np.splice(index, 1);
    setParameters(np);
  }

  const editKey = (index, value) => {
    const np = [...parameters];
    np[index].key = value;
    setParameters(np);
  }

  const editValue = (index, value) => {
    const np = [...parameters];
    np[index].value = value;
    setParameters(np);
  }

  const getParameterInputs = () => {
    const parametersInputs = [];
    for (let i = 0; i < parameters.length; i++) {
      parametersInputs.push(
        <Form.Row className="mb-3">
          <Col md="5">
            <Form.Control required placeholder="key" value={parameters[i].key} 
              onChange={(e) => editKey(i, e.target.value)}/>
          </Col>
          <Col md="5">
            <Form.Control required placeholder="value" value={parameters[i].value} 
              onChange={(e) => editValue(i, e.target.value)}/>  
          </Col>
          <Col md="2" className="text-center">
            <FontAwesomeIcon cursor="pointer" 
              icon={faMinusCircle} 
              size="2x" 
              onClick={() => removeParameter(i)}
            />
          </Col>
        </Form.Row>
      )
    }
    return parametersInputs;
  }

  const handleEndpoint = (endpoint) => setEndpoint(endpoint);

  const getEndpoints = async () => {
    try {
      const endpoints = (await ObexRequestsHandler.get('/paths')).data;
      // Agregamos el autocompletar
      autocomplete(document.getElementById('filter-input'), endpoints, handleEndpoint);
    } catch (error) {
      console.error('ERROR FETCHING ENDPOINTS ', error);
    }
  }


  useEffect(() => {
    getEndpoints();
  }, []);

  useEffect(() => {
    // Importamos prism cada vez que cambia el contenido y ejecutamos el highlightAll
    const prism = require('../../assets/js/prism.js');
    prism.highlightAll();
  })

  return (
    <DashLayout sider={DashRouters} active="developers">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
              <h1 className="mb-3">Sandbox</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form className="obex-form" onSubmit={makeRequest}>
                <Form.Row className="mb-3">
                  <Form.Label column md="12" className="obex-form-label"> Method </Form.Label>
                  <Col md="12">
                    <ToggleButtonGroup type="radio" className="obex-btn-group-method" name="method" onChange={handleMethod} value={method}>
                      <ToggleButton value="GET" className="btn-obex btn-method btn-method-get">GET</ToggleButton>
                      <ToggleButton value="POST" className="btn-obex btn-method btn-method-post">POST</ToggleButton>
                      <ToggleButton value="PUT" className="btn-obex btn-method btn-method-put">PUT</ToggleButton>
                      <ToggleButton value="PATCH" className="btn-obex btn-method btn-method-patch">PATCH</ToggleButton>
                      <ToggleButton value="DELETE" className="btn-obex btn-method btn-method-delete">DELETE</ToggleButton>
                    </ToggleButtonGroup>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label column md="12" className="obex-form-label"> Endpoint </Form.Label>
                  <Col md="12">
                    <Form.Control
                      id="filter-input"
                      placeholder="Find an available endpoint"
                      autoComplete="off"
                    />
                  </Col>
                </Form.Row>
  
                <Form.Row className="mb-3">
                  <Form.Label column md="12" className="obex-form-label"> Signature </Form.Label>
                  <Col md="8">
                    <Form.Control placeholder="Signature" onChange={handleSign}/>
                  </Col>
                  <Col md="4">
                    <Button onClick={createSign} className="btn-block obex-btn btn-green">Add Sign</Button>
                  </Col>
                </Form.Row>
                
                {getParameterInputs()}

                <Form.Row className="mb-3">
                  <Col md="3">
                    <Button className="btn-block obex-btn btn-green" onClick={addParameter}>
                      Parameters <FontAwesomeIcon cursor="pointer" icon={faPlusCircle} size="1x"/>
                    </Button>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Col>
                    <Button type="submit" disabled={loading} className="btn-block obex-btn btn-green">
                      Make Request
                      {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                    </Button>
                  </Col>
                </Form.Row>
              
              </Form>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="mb-3">
              { (result.length > 0 && !loading)  && 
                <pre className="language-json terminal-logs">
                  <code className="language-json">
                    <JSONPretty id="json-pretty" data={result}></JSONPretty>
                  </code>
                </pre>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </DashLayout>
  )
}

export default DevSandbox;