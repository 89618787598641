import React, { FunctionComponent, Component, useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button  } from 'react-bootstrap';
import ButtonSubmit from '../basicComponents/buttonSubmit';



type upgradePlanProps = {
  showModal: boolean,
  text: string,
  onCloseModal: () => void,
}

const ProjectNeeded:FunctionComponent<upgradePlanProps> = (props) => {
  
  const { showModal, onCloseModal, text } = props
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const getSettingFormat = async() => {};

  const handleSubmit = (e) => {

  };

  useEffect(() => {
    getSettingFormat();
  })

  const ContentModal = (
    <Form>
      <Form.Row>
        <Col>
          <ButtonSubmit 
            loading={loading} 
            success={success} 
            error={error} 
            handleSubmit={onCloseModal} 
            submitDisabled={submitDisabled} 
            textButton={'Close'}/>
        </Col>
      </Form.Row>
    </Form>
  )

  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={showModal} 
      onHide={onCloseModal}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col md="12" className="addons-title">
              <span>You have no projects</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
        <Row>
          <Col md="12" className="text-center">
            <span>{text}</span>
          </Col>
        </Row>
       {ContentModal}
      </Modal.Body>
    </Modal>
  )
}

export default ProjectNeeded;