const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  
  export const getNow = () => {
    const today = getToday();
    const time = getTime();
    return `${today} ${time}`;
  };
  
  export const getToday = () => {
    const { day, month, year } = getJsonDate();
    return `${year}-${month}-${day}`;
  };

  export const getWeekAgo = () => {
    const date = new Date();
    date.setDate(date.getDate() - 7);

    const year = date.getFullYear();
    const month = formartTwoDigit(date.getMonth() + 1);
    const day = formartTwoDigit(date.getDate());

    return `${year}-${month}-${day}`;
  }
  
  export const getTime = () => {
    const { seconds, minutes, hours } = getJsonDate();
    return `${hours}:${minutes}:${seconds}`;
  };
  
  export const sumOneMonth = (strDate) => {
    const date = new Date(strDate);
  
    const year = date.getFullYear();
    const month = formartTwoDigit(date.getMonth() + 2);
    const day = formartTwoDigit(date.getDate());
  
    return `${year}-${month}-${day}`;
  };
  
  export const sumOneYear = (strDate) => {
    const date = new Date(strDate);
  
    const year = date.getFullYear() + 1;
    const month = formartTwoDigit(date.getMonth() + 1);
    const day = formartTwoDigit(date.getDate());
  
    return `${year}-${month}-${day}`;
  };
  
  export const getJsonDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = formartTwoDigit(date.getMonth() + 1);
    const day = formartTwoDigit(date.getDate());
    const seconds = formartTwoDigit(date.getSeconds());
    const minutes = formartTwoDigit(date.getMinutes());
    const hours = formartTwoDigit(date.getHours());
  
    return { year, month, day, seconds, minutes, hours };
  };
  
  export const formartTwoDigit = (val) => {
    if (!val) return false;
    val = val.toString();
    return val.length >= 2 ? val : `0${val}`;
  };
  
  /**
   * Devuelve un objecto date de javascript en string YYYY-MM-DD HH:MM:SS
   * @param {date} date
   */
  export const dateToString = (date) => {
    const year = date.getFullYear();
    const month = formartTwoDigit(date.getMonth() + 1);
    const day = formartTwoDigit(date.getDate());
  
    return `${year}-${month}-${day}`;
  };
  
  
  /**
   * De una fecha en string devuelve el día por ejemplo: Monday 20/06
   * @param {string} dateString 
   */
  export const dateToNameDay = (dateString) => {
    const date = new Date(dateString);
  
    const dayNumber = formartTwoDigit(date.getDate());
    const dayName = dayNames[date.getDay()];
    const monthName = monthNames[date.getMonth()];
    const monthNumber = formartTwoDigit(date.getMonth() + 1);
  
    return `${dayName} ${dayNumber}/${monthNumber}`;
  }
  
  /**
   * Devuelve el número del día en función de una fecha en formato string
   * @param {string} dateString 
   */
  export const getDayFromStringDate = (dateString) => {
    const date = new Date(dateString);
    return date.getDate();
  }

/**
 * Devuelve el número de días que tiene este mes
 */
export const daysInThisMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const numberOfDays = new Date(year, month, 0).getDate();
  const today = date.getDate();
  return { today, numberOfDays };
};


/**
 * Devuelve la fecha del primer dia del mes
 */
export const firstDayInThisMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth();
  month++;
  month = formartTwoDigit(month);
  return `${year}-${month}-01`;
}

/**
 * Devuelve la fecha del ultimo dia del mes
 */
export const lastDayInThisMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth();
  const lastDay = formartTwoDigit(new Date(year, month, 0).getDate());
  month++;
  month = formartTwoDigit(month);
  return `${year}-${month}-${lastDay}`;
}

/**
 * Devuelve los meses que quedan hasta final de licencia
*/
export const getLeftMonthsExpires = (expires) => {
  const expiresDate = new Date(expires);
  const dateActual = new Date();
  let months;
  months = (expiresDate.getFullYear() - dateActual.getFullYear()) * 12;
  months -= dateActual.getMonth();
  months += expiresDate.getMonth();
  return months <= 0 ? 0 : months;
};