import React, { FunctionComponent } from 'react';
import { Nav, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

type FooterProps = {
    navColumn: any
};


const FooterContent:FunctionComponent<FooterProps> = (props:FooterProps) => {

  const { navColumn } = props;

  return (
    <Col className="mx-3 p-0">
      <Nav defaultActiveKey="" className="flex-column">
      <div>
        <span className="title-nav">{navColumn.titleColumn}</span>
      </div>
      { navColumn.links.map(link => { 
          return (
            <Nav.Link 
              key={'foot'+link.title} 
              className="pl-0 py-0" 
              href={link.path}><span>{link.title}</span>
            </Nav.Link>
            )
          }
        )
      }
    </Nav>
  </Col>
  );
}
export default FooterContent;