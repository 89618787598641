import React, { FunctionComponent } from 'react';
import {  Row, Col, Spinner } from 'react-bootstrap';


const FetchingSpinner:FunctionComponent<{}> = () => {

  return (
    <Row className="spinner-size">
      <Col md="1" className="mx-auto d-flex align-items-center">
        <Spinner animation="border"/>
      </Col>
    </Row>
  )
}

export default FetchingSpinner;