import React, { FunctionComponent, Fragment } from 'react'; // we need this to make JSX compile
import { Alert, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExclamationTriangle, faCheck  } from '@fortawesome/free-solid-svg-icons';



type ObexAlertProps = {
  type: string,
  msg: string
};

const ObexAlert:FunctionComponent<ObexAlertProps> = ({ type, msg }) => {

    const errorAlert = <Alert className='obex-alert-danger'>
      <Row>
        <Col md="1">
          <FontAwesomeIcon icon={faExclamationCircle}/>
        </Col>
        <Col>
          {msg}
        </Col>
      </Row>
    </Alert>

    const successAlert = <Alert className='obex-alert-success'>
      <Row>
        <Col md="1">
          <FontAwesomeIcon icon={faCheck}/>
        </Col>
        <Col>
          {msg}
        </Col>
      </Row>
    </Alert>   

    const warningAlert = <Alert className='obex-alert-warning'>
      <Row>
        <Col md="1">
          <FontAwesomeIcon icon={faExclamationTriangle}/>
        </Col>
        <Col>
          {msg}
        </Col>
      </Row>
    </Alert>   

    const typeReferences = {
      error: errorAlert,
      success: successAlert,
      warning: warningAlert
    }

    return msg.length > 0 ? typeReferences[type] : <Fragment/>;
}

export default ObexAlert;