import React, { FunctionComponent } from 'react'; 
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcAmex, faCcDinersClub, faCcMastercard } from '@fortawesome/free-brands-svg-icons';

type ProductMethodPaymentProps = {
    title: string,
    aliasPayment: string,
    descrip: string,
    typeCard: string
}

const ProductMethodPayment:FunctionComponent<ProductMethodPaymentProps> = (props: ProductMethodPaymentProps) => {

  const { title, aliasPayment, descrip, typeCard } = props;

  const icons = {
    visa: faCcVisa,
    amex: faCcAmex, 
    dinners: faCcDinersClub, 
    mastercard: faCcMastercard
  }

  return(
    <Card className="product-detail-card mb-3">
      <Card.Body className="p-3">
        <Card.Title>
          <Row>
            <Col><span className="title-product">{title}</span></Col>
          </Row>
        </Card.Title>
        <div className="border-top my-2"></div>
        <Card.Text>
          <Row>
            <Col md="6">
              <span>
                <FontAwesomeIcon icon={icons[typeCard]} className="mr-2"/>
                {descrip}
              </span>
            </Col>
            <Col md="6" className="text-right my-auto">
              <span><span className="product-price">{aliasPayment}</span></span>
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
    );
}

export default ProductMethodPayment;