import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DevDashboardLayout';
import { Row, Col, Table, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ProjectChart from './GraphComponents/ProjectsChart';
import DashRouters from '../../configs/routers/dev-dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddProjectModalSel from '../Modals/addProjectModalSelect';
import AddProjectModal from '../Modals/addProjectModal';
import { faEdit, faPencilAlt, faPlusCircle  } from '@fortawesome/free-solid-svg-icons';
import { amountToEur } from '../../services/AmountService';
import ConsumptionChart from './GraphComponents/ConsumptionChart';
import ConsumptionDevProjectChart from './GraphComponents/ConsumptionDevProjectChart';
import UpgradePlanModal from '../Modals/UpgradePlanModal';
import SessionService from '../../services/SessionsService';





type ProjectsProps = {};


const DevProjectsContent:FunctionComponent<ProjectsProps> = props => {
  const menuActive = 'projects';

  const [slotsInfo, setSlots] = useState({
      allowProjectsBySubscription: 0,
      maxAllowProjecs: 0,
      maxExtraProjects: 0,
      extraPurchasedProjects: 0,
      activeProjects: 0,
      totalSlots: 0,
      availableSlots: 0
  });

  const [loadingTable, setLoadingTable] = useState(false);
  const [possibleSlotsToBuy, setSlotsToBuy] = useState(0);
  const [projects, setProjects] = useState([]);
  const [addingProjects, setAddingProjects] = useState(false);
  const handleAddProjects = () => setAddingProjects(!addingProjects);
  const [availableAdd, setAvailableAdd] = useState(true);
  const [projectSelected, setProjectSelected] = useState('')
  const [allLoaded, setAllLoaded] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const tt=0;
  const [currentSuscripcion, setCurrentSusbscription] = useState(0);
  const [sublinks, setSublinks] = useState([
/*    {
      "path": `#`,
      "title": "Currencies",
    },
    {
      "path": `#`,
      "title": "Fees"
    }*/
  ]);

  const getProjects = async () => {
    try {
      setProjects([]);
      setLoadingTable(true);
      const projects = (await ObexRequestHandler.get('/developer_projects', { org_id: SessionService.ORGANIZATION }, true)).data || [];
      const projectsex = []
      const projectsT = [];
      projects.forEach(async(element) => {
        // if (element.isCreating) projectsT.push(element);
        projectsT.push(element);
        projectsex.push(element);
      });

      // const slots = (await ObexRequestHandler.get('/consumption/slots', {}, true)).data || {};
      // const licencia = (await ObexRequestHandler.get('/current_subscription', {}, true)).data || {};
      // setCurrentSusbscription(licencia.subscription_id);
      
      const SelectedProject = projectsT.length === 0 ? '' : projectsT[0].name;

      setProjectSelected(SelectedProject);
      setProjects(projectsex);
      // setSlots(slots);

      // setSlotsToBuy(slots.maxExtraProjects - slots.extraPurchasedProjects);

      projectsLoaded(SelectedProject);
      setAllLoaded(false);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setAllLoaded(false);
      setSublinks([/*
        {
          "path": `___no__proj__/exchange_activated`,
          "title": "Currencies",
        },
        {
          "path": `___no__proj__/commissions`,
          "title": "Fees"
        }*/
      ]);

    }
    setLoadingTable(false);
    setAllLoaded(false);
  }

  const whenClosing = () => {
    setAddingProjects(false);
    getProjects();
  }

  const projectsLoaded = (projectSelecteds) =>{

    /*
    setSublinks([
      {
        path: `${projectSelecteds}/exchange_activated`,
        title: 'Currencies',
      },
      {
        path: `${projectSelecteds}/commissions`,
        title: 'Fees'
      }
    ]);
    */

  }

  const history = useHistory();
  const routerLink = (url) => {
    const pushData = projects 
    ? { state: { projects } } 
    : {};
    history.push(url, pushData);
  }

  const loadingSpinner = <Row><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText = <Row>
                          <Col className="mx-auto text-center">
                            <span className="d-block">
                              You have not yet registered any project in your account. 
                            </span>
                            <span className="d-block">
                              Press add to register and configure your projects.
                            </span>
                          </Col>
                         </Row>;

  
    useEffect(() => {
      getProjects();
    }, []);



  
  return (

     <DashLayout sider={DashRouters} active={menuActive} sublinks= {sublinks}> 
      <Row style={{ fontWeight: 'bold' }}> 
        <Col md={10}>
          <b><h1>Projects</h1></b>
        </Col>
        {/*
        <Col md={2}>
          <Button disabled={allLoaded} className="btn-add obex-btn btn-add-green btn-block px-4" onClick={handleAddProjects}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add New </Button>
  </Col>*/}
      </Row>
      <Row>
        <Col>
        <Table responsive className="obex-projects-table">
          <thead style={{ fontFamily: 'Regular Intro Bold' }}>
            <tr>
              <th>Project Name</th>
              <th>Project ID</th>
              <th>Requests</th>
              <th>Balance</th>
              <th>Benefits</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projects.map(project =>  (
              <tr key={project.id}>
                <td>{project.description}</td>
                <td>{project.name}</td>
                <td>{project.tx.current}</td>
                <td>{amountToEur(project.balance)}</td>
                <td>{amountToEur(project.benefits)}</td>
                <td>{project.creation_date}</td>
                <td className="text-right">
                  <a className="edit-link"  onClick={() => routerLink(`/dev_projects/${project.name}`)}><FontAwesomeIcon icon={faPencilAlt} size="1x" className="mr-1"/></a>
                  </td>
              </tr>
            ))}
          </tbody>
          </Table>
        </Col>
      </Row>
      {loadingTable && loadingSpinner}
      {(!loadingTable && projects.length <= 0) && noProjectsText}
      <Row className="mb-3">
     {/*   <Col md={2}>
          <Button disabled={allLoaded} className="btn-add obex-btn btn-add-green btn-block px-4" onClick={handleAddProjects}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add New</Button>
            </Col>*/}
      </Row>
      <Row className="stats-grafic">
        <Col>
        {loadingTable || projectSelected == '' ? (loadingTable && loadingSpinner) : <ConsumptionDevProjectChart title={projectSelected} projects={projects}/>}
        </Col>
        
      </Row>
      <UpgradePlanModal 
          showModal={ addingProjects && slotsInfo.availableSlots==0 && possibleSlotsToBuy==0} 
          text={'In order to add a new project you need to upgrade your plan. \n You have consumed the maximun slots available.'} 
          onCloseModal={whenClosing} 
          currentSuscription={currentSuscripcion}
          />
      {(addingProjects && slotsInfo.availableSlots > 0) 
        ? <AddProjectModal 
          shown={addingProjects && slotsInfo.availableSlots > 0} 
          onSuccessCreation = {whenClosing} 
          onCloseAction={whenClosing}
          setAvailable={setAvailableAdd}/>
        : <AddProjectModalSel possibleProjectsToBuy={possibleSlotsToBuy} shown={addingProjects && possibleSlotsToBuy>0} onCloseAction={whenClosing}/> 
        
      }
    </DashLayout>)
  }

  // TODO possibleProjectsToBuy rescartar la info
  export default DevProjectsContent;