import React, { FunctionComponent, useState, useEffect } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus  } from '@fortawesome/free-solid-svg-icons';


type PhoneVerificationProps = {
  phoneNumber: string,
  countryCode: string,
  show: boolean,
  handleClose: () => void
}
const PhoneVerificationModal:FunctionComponent<PhoneVerificationProps> = (props) => {

  const { phoneNumber, countryCode , handleClose} = props;
  const indexEquivalences = ['firstCN', 'secondCN', 'thirdCN', 'fourthCN', 'fifthCN', 'sixthCN'];
  const invalidErrorMsg = 'Please instert a valid number.';
  const [index, setIndex] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const validateOtp = async () => {
    try {
      setLoading(true);
      let otp = '';
      for (const id of indexEquivalences) {
        const input = document.getElementById(id) as HTMLInputElement;
        const { value } = input;
        otp = `${otp}${value}`
      }
      const payload = { phoneNumber, otp, countryCode }; 
      const result = await ObexRequestHandler.post('/validate_otp', payload);
      const { success, data, message } = result;
      if (!success) throw { message };
      setSuccess('Phone Verified Successfully');
      setTimeout(() => { 
        handleClose();
      }, 2000);


    } catch (error) {
      console.error('ERROR VALIDATING OTP ', error);
      setError(error.message || error);
    }
    setLoading(false);
  }

  useEffect(() => {
    const selected = indexEquivalences[index];
    const input = document.getElementById(selected) as HTMLInputElement;
    input.select();
    input.value = '';
  }, [index])

  const isNotSelected = (id):boolean => {
    return id !== indexEquivalences[index];
  }

  const validNumber = (value) => {
    return Number.isInteger(parseInt(value));
  }

  const handleInputValue = (e) => {
    setError('');
    let newIndex = index;
    const { value } = e.target;
    const validValue = validNumber(value);
    if (validValue) {
        newIndex = index + 1;
        if (!(newIndex > 5)) setIndex(newIndex);
        if (newIndex > 5) setSubmitDisabled(false);
    } else {
      if (value.length) setError('Please insert a valid value.');
    }
  }

  const handleKeyDown = (e) => {
    let newIndex = index;
    const { keyCode } = e;
    const value = e.target.value;
    const validValue = validNumber(value);
    if (keyCode == 8){
      if (value == '') {
        newIndex = index - 1;
        setIndex(newIndex);
      }
      e.preventDefault();
      e.target.value = '';
      setError('');
    }
    const id = indexEquivalences[index];
    const input = document.getElementById(id) as HTMLInputElement;
    const hasValue = value.length > 0;
    if (newIndex !== index && !(newIndex < 0)) setIndex(newIndex);
  }

  const inputHasError = (id):boolean => {
    return ((error === 'Please insert a valid value.') && id === indexEquivalences[index]);
  }

  const returnClassName = (id):string => {
    return inputHasError(id) ? 'input-content-center input-content-lg obex-invalid-input' : 'input-content-center input-content-lg';
  }

  return(
    <Modal dialogClassName="addons-dialog obex-dialog w-75"
      show={props.show} 
      onHide={props.handleClose}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Col md="12" className="addons-title">
            <span>Phone verification</span>
          </Col>
          <Col md="12">
            <p className="modal-subtitle">
              In order to protect the security of your account we will send 
              you a text message with a verification code that your'll need 
              to enter on the nest screen
            </p>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-4">
          <Col md="10" className="mx-auto">
            <Form className="obex-form phone-verification-form">
              <Form.Row className="mb-5">
                <Col className="mr-4">
                  <Form.Control id="firstCN" className={returnClassName('firstCN')} onInput={handleInputValue} onKeyDown={handleKeyDown} disabled={isNotSelected('firstCN')} maxLength={1} required placeholder="0" />
                </Col>
                <Col className="mr-4">
                  <Form.Control id="secondCN" className={returnClassName('secondCN')} onInput={handleInputValue} onKeyDown={handleKeyDown} disabled={isNotSelected('secondCN')} maxLength={1} placeholder="0" />
                </Col>
                <Col className="">
                  <Form.Control id="thirdCN" className={returnClassName('thirdCN')} onInput={handleInputValue} onKeyDown={handleKeyDown} disabled={isNotSelected('thirdCN')} maxLength={1} placeholder="0" />
                </Col>
                <Col className="text-center d-flex flex-column my-auto">
                <FontAwesomeIcon icon={faMinus} size="2x" className="m-auto"/>
                </Col>
                <Col className="mr-4">
                  <Form.Control id="fourthCN" className={returnClassName('fourthCN')} onInput={handleInputValue} onKeyDown={handleKeyDown} disabled={isNotSelected('fourthCN')} maxLength={1} placeholder="0" />
                </Col>
                <Col className="mr-4">
                  <Form.Control id="fifthCN" className={returnClassName('fifthCN')} onInput={handleInputValue} onKeyDown={handleKeyDown} disabled={isNotSelected('fifthCN')} maxLength={1} placeholder="0" />
                </Col>
                <Col className="">
                  <Form.Control id="sixthCN" className={returnClassName('sixthCN')} onInput={handleInputValue} onKeyDown={handleKeyDown} disabled={isNotSelected('sixthCN')} maxLength={1} placeholder="0" />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="my-3 mx-auto" md="6">
                {error.length > 0 && (
                  <Alert variant='danger'>
                    {error}
                  </Alert>)}
                  {success.length > 0 && (
                  <Alert variant='success'>
                    {success}
                  </Alert>
                )}                
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="mt-5 mb-3 mx-auto" md="5">
                  <Button disabled={submitDisabled || loading} onClick={validateOtp} className="obex-btn btn-green btn-block">
                    Verify Phone Number
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <small>
              <strong>Note:</strong> Your number will not be shown publicly. 
              Others will be able to find your phone number, 
              and you can change your privacy settings at any time. 
              Standard text messages charges may apply depending an your mobile carrier.
            </small>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default PhoneVerificationModal;