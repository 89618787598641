import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DevDashboardLayout';
import DashRouters from '../../configs/routers/dev-dashboard-routers.json';
import { Row, Col, Card, Button, Form, Table, Modal  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { ReactSortable } from 'react-sortablejs';
import SessionService from '../../services/SessionsService';
import AddOrganizationModal from '../Modals/addOrganizationModal';


type IncomeProps = {
};


const DevOrganizations:FunctionComponent<IncomeProps> = ({ }) => {

  const [projects, setProjects] = useState([]);
  const [newdevelopers, setNewDevelopers] = useState([]);
  const [editing, setEdition] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filtro, setFilter] = useState('');
  const [activeTeam, setActiveTeam] = useState('');

  const changeFilter = (e) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
  }


  const handleModal = () => {
    getProjects('');
    setShowModal(!showModal);
  };

  const isTheCategory = (category, id) => {
    return category.id === id;
  }

  const handleSwitch = async (e, category, id) => {
    const index = newdevelopers.findIndex((category) => isTheCategory(category, id));
    const oo = [];
    newdevelopers.forEach((x)=>{oo.push(x)});

    oo[index].checked = !oo[index].checked;
    setNewDevelopers(oo);

    if (oo[index].checked == false){
      const resuu = (
        await ObexRequestHandler.get(`/remove_developer_team?organization=${oo[index].org_id}&dev_team=${activeTeam}&email=${oo[index].email}&dev_id=${oo[index].dev_id}`, {}, true)
        ).data || [];
      console.log(resuu);
    } else {
      const resuu = (
        await ObexRequestHandler.get(`/add_developer_team?organization=${oo[index].org_id}&dev_team=${activeTeam}&email=${oo[index].email}`, {}, true)
        ).data || [];
      console.log(resuu);
    }
  }

  const safeAndHideModal = async () => {
    const newCats = [];
    for (let id = 0; id < newdevelopers.length; id++) {
      const { email, checked } = newdevelopers[id];
      const cat = {
        id,
        email,
        checked
      }
      newCats.push(cat);
    }
    
    const allDisabled = (newCats.filter(c => c.checked)).length < 1;
    if (!allDisabled) {
  
  }
  getProjects('');

    setEdition(false);
  }

  const categoriesList = (
    <Form>
      <ReactSortable tag="div" list={newdevelopers} setList={setNewDevelopers}>
          {newdevelopers.map(c => (
            c.validated ?
            <Row key={c.id} className="my-1">
              <Col>
                <Card className="product-detail-card obex-card api-product-card">
                  <Card.Body className="py-3 px-4">
                    <Row>
                      <Col className="pl-2">
                        <FontAwesomeIcon icon={faBars} className="button-sort mr-2" size="1x"/>
                        <span className="title-product">{c.email}</span>
                      </Col>

                      
                      <Col md="2" className="text-right">
                        <Form.Check 
                          checked={c.checked}
                          type="switch"
                          size={5}
                          id={`${c.id}`}
                          label=''
                          onChange={(e) => handleSwitch(e, c, c.id)}
                          className="obex-switch"
                        />
                        
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row> : <></>
          ))}
     </ReactSortable>
    </Form>
  )

  const modal = (
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={editing} 
      onHide={safeAndHideModal}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col className="addons-title">
              <span>Developers</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
        <Row className="px-3">
          <Col>
            {categoriesList}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )

  

  const getProjects = async (filtro) => {
    try {
      setProjects([]);
      const projects = (
        await ObexRequestHandler.get(`/my_organizations?dev_id=${SessionService.DEV_ID}`, {}, true)
        ).data || [];

      if (filtro=='') setProjects(projects);
      else {

        const filter_projects = [];
        projects.forEach(element => {
          if (element.name.toLowerCase().includes(filtro.toLowerCase())) filter_projects.push(element);
        });
        setProjects(filter_projects);
      }
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    }
  }

  useEffect(() => {
    getProjects('');
  }, []);


  return(
    <DashLayout sider={DashRouters} active={'organizations'} sublinks={[]}>
      <Row>
        <Col md="9" className="mx-auto" >
       <Row style={{ marginTop:'20px' }}>
        <Col md={9} style={{ marginLeft : '-20px' }}><h1>View your Organizations</h1></Col>
        <Col md={3} style={{ paddingLeft : '-0px' }}>
        </Col>
        
       </Row>
       <Form.Row >
        <Col md="9" style={{ marginLeft : '-20px' }}>
          <Form.Control
            required type="text" 
            value={filtro} 
            placeholder="Search by Organization name" 
            onChange={changeFilter} />
        </Col>
      
            <Col md={3}>
              <Button 
                disabled={null} 
                className="btn-add obex-btn btn-add-green btn-block px-4" 
                onClick={
                  () => {getProjects(filtro)}
                  }>
                <FontAwesomeIcon icon={faSearch} size="1x" className="mr-1"/></Button>
            </Col>
        </Form.Row>

       <Row style = {{ marginTop:'20px' }}>
        <Col style={{ marginLeft : '-20px' }}>
        <Table responsive className="obex-projects-table">
          <thead style={{ fontFamily: 'Regular Intro Bold' }}>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th># Of Developers</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {projects.map(project =>  (
              <tr key={project.id}>
                <td>{project.name}</td>
                <td>{project.description}</td>
                <td>{project.developers}</td>
                <td className="text-right">
                  </td>
              </tr>
            ))}
          </tbody>
          </Table>
        </Col>
      </Row>
      {showModal && 
        <AddOrganizationModal 
          show={showModal} 
          handleClose={handleModal}/>
      }
       {modal}
       </Col>
      </Row>
    </DashLayout>
  )
}

export default DevOrganizations;


