import React, { FunctionComponent, useState, useEffect } from 'react';
import { Container, Row, Button, Col, Image, Modal, Spinner } from 'react-bootstrap';
import Ilustracion from '../../assets/img/very_account.png';
import Cookies from 'universal-cookie';
import '../../assets/css/verificationNotice.css';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';

type VerificationProps = {
  mail: string,
  show: any,
  handleClose: any
}


const VerificationNotice:FunctionComponent<VerificationProps> = (props: VerificationProps) => {

  const [error, setError] = useState(''); 
  const [success, setSuccess] = useState('');
  const [verifiying, setVerifiying] = useState(false);

  function routeLogin() {
    const path = '/login';
    window.location.href = path;
  }

  const handleVerify = async (event) => {
    setVerifiying(true);
    try {
      setError('');
      setSuccess('');
      event.preventDefault();
      event.stopPropagation();
      const signin = await ObexRequestHandler.post('/resend_validation');
      if (signin.success){
        const meee = 'A new mail to ' + props.mail + ' with your activation link was sent.';
        setSuccess(meee);
        setVerifiying(false);
        removeCookie();
        setTimeout(function() { 
          routeLogin();
          }.bind(this), 4000)
      }

    }
      catch (error) {
        setError(error.message || 'An error was ocurred while sigining.');
      }
      setVerifiying(false);
      setTimeout(function() { 
        setError('');
        }.bind(this), 4000)
    };
    
  const removeCookie = () => {
    const cookies = new Cookies();
    cookies.remove('101Obex', { path: '/' });
  }
  
  useEffect(() => {
    //removeCookie();
  }, []);
  
  return(
    <Modal dialogClassName="addons-dialog w-75"
      show={props.show} 
      onHide={props.handleClose}
      animation={false}
      backdrop="static">
    <Container id="verification-notice" className="pt-3">
      <Modal.Header closeButton className="border-0 pb-0 ">
        <Row className="mx-auto">
          <Col className="text-center">
            <span className="title-notice">Account Activation</span>
          </Col>
        </Row>
      </Modal.Header>
      <Row>
        <Col md="10" className="mx-auto text-center">
          <span className="text-center d-block"></span>
        </Col>
      </Row>
      <Row className="text-center">
        <Col className="text-center my-2">
            <span className="subtitle-notice">This account has not been activated!</span>
        </Col>
      </Row>
      <Row className="text-center">
        <Col md="10" className="text-center mx-auto">
            <p>
            This account has not yet been activated. You should have received a message in your email, 
            with instructions to activate the account. Here you can forward the email to activate your 
            account. If it does not arrive, check the Spam folder in your email and/or make sure you 
            check the appropriate email account. 
            </p>
        </Col>
      </Row>
      <Row className="pt-1 mt-1">
        <Col md={{ span:5, offset:3 }} className="px-0">
          <Image src={Ilustracion} fluid/>
        </Col>
      </Row>
      <Row className="text-center md-3-6-3 my-3">
        <Col md="3" className="text-center"></Col>
        <Col md="6" className="text-center">  
          <Row className="text-center md-3-6-3">
            <Col md="3" className="text-center"></Col>
              <Col md="6" className="text-center">
                <Button onClick={handleVerify} value="5" className="btn-block btn-green" >Resend
                
                {verifiying && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                </Button>
                
              </Col>
              <Col md="3" className="text-center"></Col>
          </Row>
        </Col>
        <Col md="3" className="text-center"></Col>
      </Row>
      <Row className="text-center md-12 pt-2 mb-2">
        <Col md="12" className="text-center">
        The email may take a few minutes to arrive.          
          <div className="row">
            <div className="col-md-3">
            </div>
            <div className="col-md-6">
            {/* {error.length > 0 && (
                        <Alert variant='danger'>
                          {error}
                        </Alert>)}
            {success.length > 0 && (
                        <Alert variant='success'>
                          {success}
                        </Alert>)} */}
            </div>
            <div className="col-md-3">
            </div>
          </div>
         
        </Col>
      </Row>
    </Container>
    </Modal>
  );
}

export default VerificationNotice;