import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, InputGroup, FormControl  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type TaxDetailProps = {
};


const TaxDetailsContent:FunctionComponent<TaxDetailProps> = ({ }) => {
  
  const [showModal, setshowModal] = useState(false);
  const handleModal = () => setshowModal(!showModal);
  const [taxCertificate, setTaxCertificate] = useState('...5616');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [taxButtonDisabled, setTaxButtonDisabled] = useState(true);
  const [typelBusiness, setTypeBusiness] = useState('');
  const [legalBusiness, setLegalBusiness] = useState('');
  const [numberIVA, setNumberIVA] = useState('');

  const handleTaxButtonDisabled = () => {
    setTaxButtonDisabled(false);
  }

  const handleTypeBusiness = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setTypeBusiness(value);
    setSubmitDisabled(false);
  }

  const handleTaxCertificate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setTaxCertificate(value);
    setSubmitDisabled(false);
  }

  const handleLegalBusiness = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setLegalBusiness(value);
    setSubmitDisabled(false);
  }

  const handleNumberIVA = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setNumberIVA(value);
    setSubmitDisabled(false);
  }

  return(
    <DashLayout sider={DashRouters} active={'setting'}>
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Tax Information</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  {/* <Card.Title>
                    <Row>
                      <Col className="text-center">
                        <span>We need to collect this information so we can submit accurate tas reports</span>
                      </Col>
                    </Row>
                  </Card.Title> */}
                  <Row>
                    <Col className="mx-auto">
                    <Form className="obex-form">
                      <Form.Row className="mb-3">
                        <Form.Label column sm="12" className="obex-form-label">
                          Type of business
                        </Form.Label>
                        <Col md="12">
                        <Form.Control as="select" custom onChange={handleTypeBusiness}>
                            <option>Limited Liability Company (LLC)</option>
                            <option>Limited Partnership</option>
                            <option>Partnership</option>
                            <option>Sole Proprietorship</option>
                            <option>Corporation</option>
                            <option>Nonprofit Organization</option>
                            <option>Cooperative (Co-op)</option>
                            <option>Sociedad de Responsabilidad Limitada (SL, SRL, SRLE)</option>
                            <option>Sociedad Anonima (S.A)</option>
                          </Form.Control>
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                        <Form.Label column sm="12" className="obex-form-label">
                          Legal business name
                        </Form.Label>
                        <Col sm="12">
                          <Form.Control required type="text" placeholder="Legal business name" onChange={handleLegalBusiness}/>
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                        <Form.Label column sm="12" className="obex-form-label">
                          Fiscal Identification Number
                        </Form.Label>
                        <Col md="12">
                        	<Row>
                            <Col md="8">
                              <FormControl
                                placeholder={taxCertificate}
                                aria-describedby="tax-certify"
                                onChange={handleTaxCertificate}
                                disabled={taxButtonDisabled}
                              />
                            </Col>
                            <Col md="4">
                              <Button className="btn-block obex-btn btn-green" onClick={handleTaxButtonDisabled} disabled={!taxButtonDisabled}>Edit</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                        <Form.Label column sm="12" className="obex-form-label">
                          Tax Identification Number
                        </Form.Label>
                        <Col sm="12">
                          <Form.Control required type="email" placeholder="ESA12345678" onChange={handleNumberIVA}/>
                        </Col>
                      </Form.Row>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="text-center">
                      <span>We'll use this number on your monthly VAT invoices, found in your Business Settings.</span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="4" className="ml-auto">
                      <Button type="submit" disabled={submitDisabled} className="btn-block obex-btn btn-green">
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashLayout>
  )
}

export default TaxDetailsContent;