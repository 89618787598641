import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import AddProjectModal from './addProjectModal';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import FetchingSpinner from '../../components/basicComponents/loaderModal';
import { amountToEur } from '../../services/AmountService';


type AddProjectModalSelProps = {
  shown: boolean,
  onCloseAction: () => void,
  onSuccess: () => void
}

const AddProjectModalSel:FunctionComponent<AddProjectModalSelProps> = (props) => {
    
  const { shown, onCloseAction, onSuccess } = props;

  const [showInputProject, setShowProjectInput] = useState(false);
  const [inputPayout, setInputPayout] = useState(0);
  const [autoRemovePayoutPerBalance, setAutoRemovePayoutPerBalance] = useState(0);
  const [autoRemovePerMonth, setAutoRemovePerMonth] = useState(false);
  const [validInputPayout, setValidInputLayout] = useState(false);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({ name: '' });
  const [loading, setLoading] = useState(false);
  const [availableAdd, setAvailableAdd] = useState(true);

  const [selection, setSelection] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    //setShowProjectInput(true);
    await withdrawal();
    onSuccess();
  }

  const withdrawal = async () => {
    try {
      setLoading(true);
      const payload = { amount: inputPayout };
      await ObexRequestHandler.post(`/projects/${project.name}/withdrawal`, payload);
    } catch (error) {
      alert('ERROR WITHDRAWING MONEY');
      console.error('ERROR WITHDRAWING MONEY ', error);
    }
    setLoading(false);
  }

  const selectAddonSelection = (event) => {
    const value = parseInt(event.target.value);
    event.preventDefault();
    event.stopPropagation();
    setSelection(value);
  }

  const removeAddonSelection = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelection(0);
  }

  const handleRechargePerMonth = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAutoRemovePerMonth(!autoRemovePerMonth);
  }

  const handleAutoRecharge = (event) => {
    const { value } = event.target;
    event.preventDefault();
    event.stopPropagation();
    setAutoRemovePayoutPerBalance(value);
  }

  const handleInputPayout = (event) => {
    const { value } = event.target;
    event.preventDefault();
    event.stopPropagation();
    setInputPayout(value);
  }

  const projectWasCreated = () => {
    setShowProjectInput(false);
    onSuccess();
  }

  const checkValidAmount = (amount) => { 
    console.log(`valid amount ${amount} : `, checkValidAmount)
    return amount.match('/d*.?d*/')
  }

  const autoRechargePerBalanceMarked = () => selection === 3;

  const inputPayoutIntroduced = () => inputPayout > 0;
  const autoRechargeIntroduce = () => autoRemovePayoutPerBalance > 0;

  const inputsAreValid = () => {
    const valuesIntroduced = (inputPayoutIntroduced() && autoRemovePerMonth)
    || (autoRechargePerBalanceMarked() && autoRechargeIntroduce()) 
    || inputPayoutIntroduced();
    return valuesIntroduced;
  }

  const getProjectsInfo = async () => {
    try {
      setLoading(true);
      const result = (await ObexRequestHandler.get('/projects')).data;
      setProjects(result);
    } catch(error) {
      console.error('ERROR FETCHING PROJECTS INFO ', error);
    }
    setLoading(false);
  }

  useEffect(() => {
    getProjectsInfo();
  }, []);

  // TODO mirar los values de los planes que sean dinamicos y se manden dinamicos al handler no hardcodeados 

  const projectsCards = projects.map(p => 
    <Row className="addon addons-border py-3 px-4 mb-4 position-relative">
      <Col md="8">
        <Row>
          <Col className="addons-title">
            <span>{p.description}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>{`Withdrawal the maximun amount of ${amountToEur(p.benefits)}`}</span>
          </Col>
        </Row>
      </Col>
      <Col md="2" className="price-addons">
        <span>{amountToEur(p.benefits)}</span>
      </Col>
      <Col md="2">
        <Button className="btn-block btn-add btn-add-green" onClick={() => setProject(p)}>Select</Button>
      </Col>
    </Row>
  )

  const modalBodyWhenProjectSelected = <Fragment>
      <Modal.Body>
      <Form className="obex-form">
        <Container fluid>
          <Row className={'addon addons-border py-3 px-4 mb-4 position-relative' +
          (inputPayoutIntroduced() && ' addons-active')}>
          {inputPayoutIntroduced() && <FontAwesomeIcon icon={faCheck} />}
            <Col md="8">
              <Row>
                <Col md="12" className="addons-title">
                  <span>Input to payout</span>
                </Col>
                <Col md="12">
                  <span>Take full control over your deliverability, distribute your mail traffic across multiple IPs</span>
                </Col>
              </Row>
            </Col>
            <Col md="4" className="my-auto">
              <Form.Row>
                <Col>
                  <Form.Control required type="number" placeholder="Amount" onChange={handleInputPayout}/>
                </Col>
              </Form.Row>
            </Col>
          </Row>

          <Row className={'addon addons-border py-3 px-4 mb-4 position-relative' +
          (autoRemovePerMonth && ' addons-active')}>
          {autoRemovePerMonth && <FontAwesomeIcon icon={faCheck} />}
            <Col md="8">
              <Row>
                <Col md="12" className="addons-title">
                  <span>Auto payout every month</span>
                </Col>
                <Col md="12">
                  <span>Gain further insights with additional history and API access</span>
                </Col>
              </Row>
            </Col>
            <Col md="4" className="my-auto">
              <Form.Row>
                <Col>
                  {autoRemovePerMonth ? 
                    <Button value="2" className="btn-block btn-remove-addons" onClick={handleRechargePerMonth}>Remove</Button>:
                    <Button value="2" className="btn-block btn-add btn-add-green" onClick={handleRechargePerMonth}>Add</Button>
                  }
                </Col>
              </Form.Row>
            </Col>
          </Row>

          <Row className={'addon addons-border py-3 px-4 mb-4 position-relative' +
          ((autoRechargePerBalanceMarked() && autoRechargeIntroduce()) && ' addons-active')}>
          {(autoRechargePerBalanceMarked() && autoRechargeIntroduce()) && <FontAwesomeIcon icon={faCheck} />}
            <Col md="8">
              <Row>
                <Col md="12" className="addons-title">
                  <span>Auto payout by ballance</span>
                </Col>
                <Col md="12">
                  <span>Take full control over your deliverability distribute your mail traffic across multiple IPs</span>
                </Col>
              </Row>
            </Col>
            <Col md="4" className="my-auto">
              <Form.Row>
                <Col className="mb-3">
                  <Form.Control type="number" placeholder="Amount" onChange={handleAutoRecharge}/>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  {autoRechargePerBalanceMarked() ? 
                    <Button value="3" className="btn-block btn-remove-addons" onClick={removeAddonSelection}>Remove</Button>:
                    <Button value="3" className="btn-block btn-add btn-add-green" onClick={selectAddonSelection}>Add</Button>
                  }
                </Col>
              </Form.Row>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col className="text-center">
              {(inputsAreValid()) ? 
              <Button disabled={loading} className="btn-submit-addons with-add" onClick={handleSubmit}>
                {loading && <Fragment>&nbsp; <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/> </Fragment>}
                Continue to Checkout
              </Button> :
              <Button className="btn-submit-addons">Select an Option</Button>}
            </Col>
          </Row>

          <Row className="mb-2">
            <Col className="text-center">
              <Button className="btn-submit-addons" onClick={() => setProject({ name:'' })}>Back to Projects</Button>
            </Col>
          </Row>
          
          <Row>
            <Col className="text-center">
              <span>*Taxes and fees may apply</span>
            </Col>
          </Row>
        </Container>
        </Form>
      </Modal.Body>
      {showInputProject && 
        <AddProjectModal 
          shown={showInputProject} 
          onSuccessCreation = {projectWasCreated} 
          onCloseAction={() => setShowProjectInput(false)}
          setAvailable={setAvailableAdd}
        />
      }
  </Fragment>
  
  const selectProjectModalBody = <Modal.Body>
    <Container fluid>
      {loading ? <FetchingSpinner/> :
      <Fragment>
        { projects.length > 0 ?
          projectsCards
        : <span></span>
        }
      </Fragment>
    }
    </Container>
  </Modal.Body>

  return(
    <Modal dialogClassName="addons-dialog w-75"
      show={shown} 
      onHide={onCloseAction}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center w-100">
          <Row>
            <Col className="addons-title">
              <span>Payout</span>
            </Col>
          </Row>
          <Row>
            <Col md="10" className="mx-auto text-center modal-subtitle">
              <span className="text-center d-block">
                { project.name.length > 0 ? 'Select your option and continue to checkout.' : 'Select a project to withdraw funds.' }
              </span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      { project.name.length > 0 ? modalBodyWhenProjectSelected : selectProjectModalBody }
    </Modal>
  );
}

export default AddProjectModalSel;


