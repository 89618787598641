import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddProjectModalSel from '../Modals/addProjectModalSelect';
import AddProjectModal from '../Modals/addProjectModal';
import { faPencilAlt, faPlusCircle  } from '@fortawesome/free-solid-svg-icons';
import { amountToEur } from '../../services/AmountService';
import ConsumptionProjectChart from './GraphComponents/ConsumptionProjectChart';
import UpgradePlanModal from '../Modals/UpgradePlanModal';





type ProjectsProps = {

};


const ProjectsContent:FunctionComponent<ProjectsProps> = (props:ProjectsProps) => {
  const menuActive = 'projects';

  const [slotsInfo, setSlots] = useState({
      allowProjectsBySubscription: 0,
      maxAllowProjecs: 0,
      maxExtraProjects: 0,
      extraPurchasedProjects: 0,
      posibleSlotsToBuy: 0,
      activeProjects: 0,
      totalSlots: 0,
      availableSlots: 0
  });
  const {} = props
  const [loadingTable, setLoadingTable] = useState(false);
  const [possibleSlotsToBuy, setSlotsToBuy] = useState(0);
  const [availableSlots, setavailableSlots] = useState(0);
  const [projects, setProjects] = useState([]);
  const [addingProjects, setAddingProjects] = useState(false);
  const handleAddProjects = () => {
    setAddingProjects(!addingProjects);
  }
  const [availableAdd, setAvailableAdd] = useState(true);
  const [projectSelected, setProjectSelected] = useState('')
  const [allLoaded, setAllLoaded] = useState(true);
  const [currentSuscripcion, setCurrentSusbscription] = useState(0);
  const [sublinks, setSublinks] = useState([
    {
      id: 'currencies',
      path: '#',
      title: 'Currencies',
    },
    {
      id: 'fees',
      path: '#',
      title: 'Fees'
    }
  ]);

  const getProjects = async () => {
    try {
      const projectsex = []
      setProjects([]);
      setLoadingTable(true);
      const projects = (await ObexRequestHandler.get('/projects?withbalances=true', {}, true)).data || [];
      const projectsT = [];
      projects.forEach(async(element) => {
        if (element.isCreating) projectsT.push(element);
        const productionProject = (await ObexRequestHandler.get(`/is_in_production?project_id=${element.id}`, {}, true)).data || {};
        element.production = productionProject.production;
        projectsex.push(element);
      });

      const slots = (await ObexRequestHandler.get('/consumption/slots', {}, true)).data || {};
      const licencia = (await ObexRequestHandler.get('/current_subscription', {}, true)).data || {};

      console.log(slots);
      console.log(licencia);


      setavailableSlots(slots.availableSlots);
      setCurrentSusbscription(licencia.subscription_id);
      const SelectedProject = projectsT.length === 0 ? '' : projectsT[0].name;
      setProjectSelected(SelectedProject);
      setProjects(projectsex);
      console.log(projectsex)
      setSlots(slots);

      setSlotsToBuy(slots.maxExtraProjects - slots.extraPurchasedProjects);

      
      // console.log('PROJECT_SELECTERD');
      // console.log(projects[0].name);

      projectsLoaded(SelectedProject);

      setAllLoaded(false);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setAllLoaded(false);
      setSublinks([
        {
          id: 'currencies',
          path: '___no__proj__/exchange_activated',
          title: 'Currencies',
        },
        {
          id: 'fees',
          path: '___no__proj__/commissions',
          title: 'Fees'
        }
      ]);

    }
    setLoadingTable(false);
    setAllLoaded(false);
  }

  const whenClosing = () => {
    setAddingProjects(false);
    getProjects();
  }

  const projectsLoaded = (projectSelecteds) =>{

    setSublinks([]);
    // console.log('*-**');
    // console.log(projectSelecteds);
    if (projectSelecteds!=''){
    setSublinks([
      {
        id: 'currencies',
        path: `${projectSelecteds}/exchange_activated`,
        title: 'Currencies',
      },
      {
        id: 'fees',
        path: `${projectSelecteds}/commissions`,
        title: 'Fees'
      }
    ]);
  }
    

  }

  const history = useHistory();
  const routerLink = (url) => {
    const pushData = projects 
    ? { state: { projects } } 
    : {};
    history.push(url, pushData);
  }

  const loadingSpinner = <Row><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText = <Row>
                          <Col className="mx-auto text-center">
                            <span className="d-block">
                              You have not yet registered any project in your account. 
                            </span>
                            <span className="d-block">
                              Press add to register and configure your projects.
                            </span>
                          </Col>
                         </Row>;

  
    useEffect(() => {
      getProjects();
    }, []);



  
  return (

     <DashLayout sider={DashRouters} active={menuActive} sublinks= {sublinks}> 
      <Row style={{ fontWeight: 'bold' }}> 
        <Col md={10}>
          <b><h1>Projects</h1></b>
        </Col>
        <Col md={2}>
          <Button disabled={allLoaded} className="btn-add obex-btn btn-add-green btn-block px-4" onClick={handleAddProjects}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add New </Button>
        </Col>
      </Row>
      <Row>
        <Col>
        <Table responsive className="obex-projects-table">
          <thead style={{ fontFamily: 'Regular Intro Bold' }}>
            <tr>
              <th>Project Name</th>
              <th>Project ID</th>
              <th>Requests</th>
              <th>Balance</th>
              <th>Benefits</th>
              <th>Date</th>
              <th>Production</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projects.map(project =>  (
              <tr key={'Projects'+project.id+Math.random()}>
                <td>{project.description}</td>
                <td>{project.name}</td>
                <td>{project.tx.current}</td>
                <td>{amountToEur(project.balance)}</td>
                <td>{amountToEur(project.benefits)}</td>
                <td>{project.creation_date}</td>
                <td>{project.production ? 'true':'false'}</td>
                <td className="text-right">

                {!project.isCreating ?
                  <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>
                  :
                  <a className="edit-link"  onClick={() => routerLink(`/projects/${project.name}`)}><FontAwesomeIcon icon={faPencilAlt} size="1x" className="mr-1"/></a> 
                }
                  </td>
              </tr>
            ))}
          </tbody>
          </Table>
        </Col>
      </Row>
      {loadingTable && loadingSpinner}
      {((!loadingTable && projects.length <= 0)) && noProjectsText}
      <Row className="mb-3">
        <Col md={2}>
          <Button disabled={allLoaded} className="btn-add obex-btn btn-add-green btn-block px-4" onClick={handleAddProjects}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add New</Button>
        </Col>
      </Row>
      <Row className="stats-grafic">
        <Col>
        {loadingTable || projectSelected == '' ? (loadingTable && loadingSpinner): <ConsumptionProjectChart title={projectSelected} projects={projects.filter(rr => rr.isCreating)}/>}
        </Col>
        
      </Row>
      <UpgradePlanModal 
          showModal={ addingProjects && availableSlots == 0 && possibleSlotsToBuy == 0 } 
          text={'In order to add a new project you need to upgrade your plan.'} 
          onCloseModal={whenClosing} 
          currentSuscription={currentSuscripcion}
          />
      {(addingProjects && availableSlots > 0) 
        ? <AddProjectModal 
          shown={addingProjects && availableSlots > 0} 
          onSuccessCreation = {whenClosing} 
          onCloseAction={whenClosing}
          setAvailable={setAvailableAdd}/>
        : <AddProjectModalSel possibleProjectsToBuy={possibleSlotsToBuy} shown={addingProjects && possibleSlotsToBuy>0} onCloseAction={whenClosing}/> 
        
      }
    </DashLayout>)
  }

  // TODO possibleProjectsToBuy rescartar la info
  export default ProjectsContent;