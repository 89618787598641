import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import PaymentConfirmation from './paymentConfirmation';
import PaymentSuccess from './paymentSuccess';
import FetchingSpinner from '../basicComponents/loaderModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { parseToCoin } from '../../services/AmountService';
import ObexToast from '../basicComponents/ObexToasts';

type SupportModalProps = {
  shown: boolean,
  onCloseAction: () => void,
  onSuccess: () => void
}

const SupportModal:FunctionComponent<SupportModalProps> = (props) => {
    
  const defaultSelectedPlan = { id: 0, monthly_price: 0, annual_price: 0, requests_number:0 } // Seteamos esto para por defecto no tener ninguno marcado
  const { shown, onCloseAction, onSuccess } = props;

  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [supportPlans, setSupportPlans] = useState([]);
  const [supportPlanSelected, setSelectedPlan] = useState(defaultSelectedPlan);
  const [paymentEnable, setPaymentEnable] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const getPlans = async () => {
    try {
      setFetchingPlans(true);
      const plans = (await ObexRequestHandler.get('/support_plans')).data;

      var ordered_plans = []
      ordered_plans = plans.sort((a, b) => a.id > b.id);


      setSupportPlans(ordered_plans);
    } catch (error) {
      console.error('ERROR FETCHING SUPPORT PLANS ', error);
      setError(error.message || error);
    }
    setFetchingPlans(false);
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const gotoContact = () => {
    //window.location.replace('https://www.101obex.com/#comp-jxad0a3m');
    window.open('https://www.101obex.com/#comp-jxad0a3m', "_blank")
  }
  const makePayment = async () => {
    try {
      startPayment();
      const payload = { supportPlanId: supportPlanSelected.id };
      const result = await ObexRequestHandler.post('/support_plans', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentEnable(false);
      onSuccess();
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA STORAGE ', error)
    }
    setLoading(false);
  }

  const removeSelectedPlan = () => setSelectedPlan(defaultSelectedPlan);

  // TODO MOSTRAR DECIMALES
  const supportPlansRows = supportPlans.map(r => (
    <Row className={'addon addons-border py-3 px-2 mb-4 position-relative' + (supportPlanSelected.id === r.id && ' addons-active')}>
    {supportPlanSelected.id === r.id && <FontAwesomeIcon icon={faCheck} />}
      <Col md="12" className="addons-title">
        <span>{r.title}</span>
      </Col>
      <Col>
        <Row>
          <Col md="6">
            <span>{r.description}</span>
          </Col>
          {r.id<3 &&
          <Col className="price-addons">
            <span>{`${parseToCoin(r.monthly_price)}/mo*`}</span>
          </Col>
          }
          {r.id<3 ?
          <Col>
            {supportPlanSelected.id === r.id ? 
              <Button value="5" className="btn-block obex-btn btn-orange" onClick={removeSelectedPlan}>Remove</Button>:
              <Button value="5" className="btn-block btn-add-support btn-add-green" style={{ marginLeft: "auto" }} onClick={() => setSelectedPlan(r)}>Add</Button>
            }
          </Col>:
          <Col>
          {supportPlanSelected.id === r.id ? 
            <Button value="5" className="btn-block obex-btn btn-orange" onClick={removeSelectedPlan}>Remove</Button>:
            <Button value="5" className="btn-block btn-add-support btn-add-green" style={{ marginLeft: "auto" }} onClick={() => gotoContact()}>Contact Sales</Button>
          }
        </Col>
          }

        </Row>
      </Col>
    </Row>
  ))

  const selectPlanModalContent = (
    <Container fluid className="mh-100">
      {fetchingPlans ? <FetchingSpinner/> : supportPlansRows}
      <Row className="mb-2">
        <Col className="text-center">
          {supportPlanSelected.id > 0
            ? <Button className="obex-btn btn-green with-add" onClick={() => setPaymentEnable(true)}>Continue to Checkout</Button>
            : <Button className="obex-btn btn-orange">Select an Option</Button>
          }
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <span>*Taxes and fees may apply</span>
        </Col>
      </Row>
    </Container>
  )
  useEffect(() => {
    getPlans();
    console.log("AQUI ES")
  }, [])

  return(
    <Fragment>
      <Modal dialogClassName="addons-dialog w-75 obex-dialog"
        show={shown} 
        onHide={onCloseAction}
        animation={false}>
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="text-center w-100">
            <Row>
              <Col className="addons-title">
                <span>ISSUE360 Official Support</span>
              </Col>
            </Row>
            <Row>
              <Col md="10" className="mx-auto text-center modal-subtitle">
              {paymentEnable ?
              <span className="text-center d-block">Review your payment and proceed.</span>:
              <span className="text-center d-block">Choose your package and continue to checkout.</span>
              }
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentEnable ? 
            <PaymentConfirmation
              actionSubmit={makePayment}
              handleGoBack={() => setPaymentEnable(false)}
              nameServiceSelected="Purchasing support."
              amountMonth={supportPlanSelected.monthly_price}
              amountYear={supportPlanSelected.annual_price}
              processingPayment={loading}
              success={success}
              error={error}
            />
            : (paymentSuccess ? <PaymentSuccess onCloseAction={onCloseAction} paymentDescription={''} paymentType={''}/> : selectPlanModalContent)
          }
        </Modal.Body>
      </Modal>    
      <ObexToast type='error' msg={error} clean={() => setError('')}/>
      <ObexToast type='success' msg={success} clean={() => setSuccess('')}/>              
    </Fragment>
  );
}

export default SupportModal;


