import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import AddProjectModal from './addProjectModal';
import PaymentConfirmation from './paymentConfirmation';
import FetchingSpinner from '../basicComponents/loaderModal';
import PaymentSuccess from './paymentSuccess';
import { amountToEur, amountToCoin } from '../../services/AmountService';


type AddBalancePluginProps = {
  shown: boolean,
  onCloseModal: () => void,
  pluginName: string,
  pluginPrice: number,
  plug_project_id: string,
  rep_pk: string,
  rep_id: string,
  project: string,
  exchangeFactor: number,
  currency: string
}

const AddBalancePluginModal:FunctionComponent<AddBalancePluginProps> = (props) => {
    
  const { shown, onCloseModal, pluginName, pluginPrice, plug_project_id , rep_pk, rep_id, project, exchangeFactor, currency } = props

  const [showInputProject, setShowProjectInput] = useState(false);
  const [inputPayout, setInputPayout] = useState(0);
  const [autoRechargePayoutPerBalance, setAutoRechargePayoutPerBalance] = useState(0);
  const [autoRechargerPerMonth, setAutoRechargePerMonth] = useState(false);
  const [validInputPayout, setValidInputLayout] = useState(false);
  const [serviceSelected, setServiceSelected] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentEnable, setPaymentEnable] = useState(false);

  const [continueSelected, setContinueSelected] = useState(false);
  const handleGoBack = () =>{setContinueSelected(!continueSelected)}

  const [selection, setSelection] = useState(0);


  const [projects, setProjects] = useState([]);
  //const [project, setProject] = useState({ name: '' });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setSuccess('');
    setError('');
    await chargeBalance();
    onCloseModal();
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }
  


  const chargeBalance = async () => {
    try {
      setLoading(true);
      const payload = { amount: inputPayout, 
                        rep_id: rep_id, 
                        rep_pk: rep_pk, 
                        proj_plug_id: plug_project_id,
                        exchangeFactor: exchangeFactor
                      };
      await ObexRequestHandler.post(`/recharge_plugin/${project}`, payload);
      setPaymentSuccess(true);
      setPaymentEnable(false);
    } catch (error) {
      alert('ERROR CHARGING BALANCE');
      console.error('ERROR RECHARGIN PLUGING');
    }
    setLoading(false);
  }


const handleExit = () => {
  setPaymentSuccess(false);
  onCloseModal();
}

  const selectAddonSelection = (event) => {
    const value = parseInt(event.target.value);
    event.preventDefault();
    event.stopPropagation();
    setSelection(value);
  }

  const removeAddonSelection = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelection(0);
  }

  const handleRechargePerMonth = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAutoRechargePerMonth(!autoRechargerPerMonth);
  }

  const handleAutoRecharge = (event) => {
    const { value } = event.target;
    event.preventDefault();
    event.stopPropagation();
    setAutoRechargePayoutPerBalance(value);
  }

  const handleInputPayout = (event) => {
    const { value } = event.target;
    event.preventDefault();
    event.stopPropagation();
    setInputPayout(value);
    setServiceSelected(`Adding ${value} to the balance.`);
  }

  const checkValidAmount = (amount) => { 
    console.log(`valid amount ${amount} : `, checkValidAmount)
    return amount.match('/d*.?d*/')
  }

  const autoRechargePerBalanceMarked = () => selection === 3;

  const inputPayoutIntroduced = () => inputPayout > 0;
  const autoRechargeIntroduce = () => autoRechargePayoutPerBalance > 0;

  const inputsAreValid = () => {
    const valuesIntroduced = (inputPayoutIntroduced() && autoRechargerPerMonth)
    || (autoRechargePerBalanceMarked() && autoRechargeIntroduce()) 
    || inputPayoutIntroduced();
    return valuesIntroduced;
  }

  useEffect(() => {
    setSuccess('');
    setError('');
  }, [])

  // TODO mirar los values de los planes que sean dinamicos y se manden dinamicos al handler no hardcodeados
  
  const modalBodyWhenProjectSelected =  <Modal.Body>
  <Container fluid>
    <Form className="obex-form">
      <Row className={'addon addons-border py-3 px-4 mb-4 position-relative' +
      (inputPayoutIntroduced() && ' addons-active')}>
      {inputPayoutIntroduced() && <FontAwesomeIcon icon={faCheck} />}
        <Col md="8">
          <Row>
            <Col md="12" className="addons-title">
              <span>Amount on EUR to add</span>
            </Col>
            <Col md="12">
              <span></span>
            </Col>
          </Row>
        </Col>
        <Col md="4" className="my-auto">
          <Form.Row>
            <Col className="">
              <Form.Control required type="number" placeholder="Amount" onChange={handleInputPayout}/>
            </Col>
          </Form.Row>
        </Col>
      </Row>

      <Form.Row className="mb-2">
        <Col className="text-center">
          {(inputsAreValid()) ? 
          <Button disabled={loading} className="btn-submit-addons with-add" onClick={() => {setPaymentEnable(true)}}>
            {loading && <Fragment>&nbsp; <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/> </Fragment>}
            Continue to Checkout
          </Button> :
          <Button className="btn-submit-addons">Introduce a value</Button>}
        </Col>
      </Form.Row>

      <Row>
        <Col className="text-center">
          <span>* You'll receive {amountToCoin(inputPayout*exchangeFactor)} {currency} on your plugin, Taxes and fees may apply</span>
        </Col>
      </Row>

    </Form>
  </Container>
  </Modal.Body>

  return(
    
    <Modal dialogClassName="addons-dialog w-75"
      show={shown} 
      onHide={onCloseModal}
      onClose={onCloseModal}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center w-100">
          <Row>
            <Col className="addons-title">
            <span>{pluginName} plugin settings</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
        <span className="m-3"><h5>Add Balance to {pluginName} plugin</h5></span>
   
      <div className="border-top border-black my-2 mx-3"></div>

      <Modal.Body>
        {paymentEnable ? 
          <PaymentConfirmation
            actionSubmit={chargeBalance}
            handleGoBack={() => setPaymentEnable(false)}
            nameServiceSelected={`Adding ${inputPayout} EUR to ${pluginName}.`}
            amountMonth={0}
            amountYear={inputPayout}
            processingPayment={loading}
            success={success}
            error={error}
          />
          : (paymentSuccess ? <PaymentSuccess onCloseAction={handleExit} paymentDescription={''} paymentType={''}/> : modalBodyWhenProjectSelected)
        }
      </Modal.Body>
    </Modal>
  );
}

export default AddBalancePluginModal;


