import React, { FunctionComponent } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';


const PricingFooter:FunctionComponent<{}> = () => {
  return (
    <Container fluid className="pricing-footer pt-4 pb-2">
      <Row className="pricing-box mx-auto mb-2">
        <Col md={2} className="mx-auto social-icons text-center">
          <a href="#" className="mx-2"><FontAwesomeIcon icon={faFacebookF} /></a>
          <a href="#" className="mx-2"><FontAwesomeIcon icon={faTwitter} /></a>
          <a href="#" className="mx-2"><FontAwesomeIcon icon={faLinkedinIn} /></a>
        </Col>
      </Row>
      <Row className="pricing-box mx-auto">
        <Col className="text-center">
          <p className="copyright">&copy;2019-2020 101OBEX, CORP</p>
        </Col>
      </Row>
    </Container>
  )
}

export default PricingFooter;