import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Button, Row, Col, Form, Alert, Spinner, Card, Modal } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ProductDetailCard from '../Signup/productDetailCard';
import ProductResumeCard from '../Signup/productResumeCard';
import ProductMethodPayment from '../Signup/productMethodPayment';
import { getLeftMonthsExpires } from '../../services/TimeService';
import SessionService from '../../services/SessionsService';


type PaymentConfirmationProps = {
  actionSubmit: () => Promise<void>,
  handleGoBack: () => void,
  nameServiceSelected: string,
  amountMonth: number,
  amountYear: number,
  processingPayment?: boolean,
  success?: string,
  error?: string
}

const PaymentConfirmationModal:FunctionComponent<PaymentConfirmationProps> = (props: PaymentConfirmationProps) => {

  const { actionSubmit, handleGoBack, nameServiceSelected, amountMonth, amountYear, processingPayment, success, error } = props;

  const [typeMethod, setTypeMethod] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [aliasPayment, setAliasPayment] = useState('');
  const [fetchingCard, setFetchingCard] = useState(false);
  const [annualPayment, setAnnualPayment] = useState(false);
  const [letMonths, setLetMonths] = useState(0);

  const getCards = async () => {
    try {
      setFetchingCard(true);
      console.log('RECUPERANDO TARJETAS');
      //const result = (await ObexRequestHandler.get('/cards')).data || {};
      //const { defaultCard, cards } = result;
      
      setTypeMethod(SessionService.StripeDefaultCard.brand);
      setPaymentMethod(`...${SessionService.StripeDefaultCard.last4}`);
      const aliasPayment = SessionService.StripeDefaultCard.alias === undefined || SessionService.StripeDefaultCard.alias === 'undefined' ? SessionService.StripeDefaultCard.alias.replace('undefined', '') : SessionService.StripeDefaultCard.alias;
      console.log(aliasPayment);
      setAliasPayment(aliasPayment);
      
      /*if (cards) {
        const cd = cards.find(c => c.id === defaultCard);
        setTypeMethod(cd.brand.toLowerCase());
        setPaymentMethod(`...${cd.last4}`);
        setAliasPayment(cd.alias);
      }*/


    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
    }
    setFetchingCard(false);
  }

  const getSubscriptionPayment = async () => {
    try {
      const result = (await ObexRequestHandler.get('/subscriptions_payment'));
      const { success, data } = result;
      if (success) {
        setLetMonths(getLeftMonthsExpires(data.expires))
        setAnnualPayment(data.annual);
      }
    } catch (error) {
      console.error('ERRRO FETCHING CARDS ', error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    actionSubmit();
  }

  useEffect(() => {
    getSubscriptionPayment();
    getCards();
  }, [])

  return(

    <Row>
      <Col md="10" className="mx-auto">
        <Form className="obex-form" onSubmit={handleSubmit}>
          <ProductDetailCard title={'Selected service'} 
            priceMonthly={amountMonth} 
            priceYear={amountYear} 
            descrip={nameServiceSelected} 
            annualPayment={annualPayment}
            letMonths={letMonths + 1}
          />
          <ProductMethodPayment title={'Payment Method'} 
            aliasPayment={aliasPayment} 
            typeCard={typeMethod} 
            descrip={paymentMethod}
          />
          <ProductResumeCard title={'Total price'} 
            priceMonthly={amountMonth} 
            priceYear={amountYear} 
            prorated={false}
            annualPayment={annualPayment} 
            letMonths={letMonths}
            ProratedPrice={null}
          />
          <Form.Row className="my-3">
            {(success && success.length > 0) &&
              <Alert variant='success'>
                {success}
              </Alert>          
            }
            {(error && error.length > 0) &&
              <Alert variant='danger'>
                {error}
              </Alert>
            }          
          </Form.Row>     
          <Form.Row className="my-3">
          <Col md="4" className="mr-auto">
              <Button onClick={handleGoBack} className="obex-btn btn-orange btn-block">Go back</Button>
            </Col>
            <Col md="4" className="ml-auto">
              <Button disabled={processingPayment} type="submit" className="obex-btn btn-green btn-block">
                Confirm
                {processingPayment && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                {(success && success.length > 0) && <Fragment>&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck}/></Fragment>}
              </Button>
            </Col>
          </Form.Row>     
        </Form>
      </Col>
    </Row>

  )
}

export default PaymentConfirmationModal;