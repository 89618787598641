import React, { FunctionComponent, Component, useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button  } from 'react-bootstrap';
import PaymentConfirmation from './paymentConfirmation';
import PaymentSuccess from './paymentSuccess';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import ObexToast from '../basicComponents/ObexToasts';


type buyPluginProps = {
  showModal: boolean,
  onCloseModal: () => void,
  onSuccess: () => void,
  pluginName: string,
  pluginPrice: number,
  pluginId: number,
  project: string,
}

const BuyPluginModal:FunctionComponent<buyPluginProps> = (props) => {
  
  const { showModal, onCloseModal, pluginName, pluginPrice, pluginId, project, onSuccess } = props
  const [paymentEnable, setPaymentEnable] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const makePayment = async () => {
    try {
      setLoading(true);
      const result = await ObexRequestHandler.post(`/plugins/${project}`, { plugin_id: pluginId });
      setLoading(false);
      const { success, data, message } = result;
      if (!success) throw { message };
      setSuccess(data);
      setTimeout(onSuccess, 1000);
    } catch (error) {
      console.error(error);
      setError(error.message || error);
    }
  };

  const ContentModal = (
    <Row>
      <Col>
        <Row className="px-3">
          <Col className="plugin-detail">
            <span className="plugin-name">{pluginName} </span>
            <span className="obex-plugin-price">{pluginPrice} EUR/month</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={() => setPaymentEnable(true)}>Continue</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={showModal} 
      onHide={onCloseModal}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col className="addons-title">
              <span>{`Buy ${pluginName.toLowerCase()} plugin`}</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
      {paymentEnable ? 
          <PaymentConfirmation
            actionSubmit={makePayment}
            handleGoBack={() => setPaymentEnable(false)}
            nameServiceSelected={`Buying ${pluginName.toLowerCase()} plugin.`}
            amountMonth={pluginPrice}
            amountYear={pluginPrice}
            processingPayment={loading}
            success={success}
            error={error}
          />
          : (paymentSuccess ? <PaymentSuccess onCloseAction={onCloseModal} paymentDescription={''} paymentType={''}/> : ContentModal)
        }
      </Modal.Body>
      <ObexToast type="error" msg={error} clean={() => setError('')}/>
      <ObexToast type="success" msg={success} clean={() => setSuccess('')}/>
    </Modal>
  )
}

export default BuyPluginModal;