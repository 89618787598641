import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/img/logo_negro.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import Cookies from 'universal-cookie';

type ChangePasswordProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const ChangePassword:FunctionComponent<{}> = () => {

  const { token } = useParams();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      if (password !== repeatPassword) throw { message: 'Passwords mismatch' };
      const payload = { token, password };
      const result = await ObexRequestHandler.post('/reset_password', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);

      setTimeout(function() { 
        const path = '/login';
        window.location.href = path;
        }.bind(this), 4000)


    } catch (error) {
      console.error('ERROR WHILE CHANGING PASSWORD ', error);
      setError(error.message || error);
    }
    setLoading(false);
  };
  
  const handleFirstPass = (e) => {
    const { value } = e.target;
    setPassword(value);
  }

  const handleSecondPass = (e) => {
    const { value } = e.target;
    setRepeatPassword(value);
  }

  return(
    <Container fluid className="text-center d-flex flex-column min-vh-100">
      <Row className="justify-content-center pt-5 mt-5">
        <div className="border rounded px-5 pt-3 pb-4 login-form">
          <Row className="justify-content-center my-4">
            <Col xs="5">
              <Image src={Logo} fluid/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control onChange={handleFirstPass} required type="password" placeholder="New Password"/>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control onChange={handleSecondPass} required type="password" placeholder="Repeat new Password"/>
                </Form.Group>
                <Button type="submit" className="my-2 login btn-block">
                  Reset Password
                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                </Button>
                {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
                {success.length > 0 && (
                  <Alert variant='success'>
                    {success}
                  </Alert>)}
              </Form>
            </Col>
          </Row>
        </div>
      </Row>
      {/*}
      <Row className="mt-auto">
        <Col className="px-0">
          <Footer/>
        </Col>
      </Row>
                */}
    </Container>
  );
}


export default ChangePassword;