import React, { FunctionComponent } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import Logo from '../../assets/img/101OBX-plans.png';

const PricingMenu:FunctionComponent<{}> = () => {
  return (
    <Row className={'py-0 w-100 pricing-header'} id="header">
      <div className="p-0 mx-auto pricing-box-menu h-100 d-flex flex-column">
        <Row className="menu-header my-auto">
          <Col md={4} className="text-left d-flex flex-column">
            <a href="https://www.101obex.com" className="my-auto">
              <img className="logo-pricing" src={Logo}/>
            </a>
          </Col>
          <Col className="links-box">
            
            <a href="/Plans" className="link link-header link-active">Pricing</a>
            <a target="_blank" rel="noopener noreferrer"  href="/" className="link link-header">Developer</a>
            <a target="_blank" rel="noopener noreferrer"  href="https://www.101obex.com/blog" className="link link-header">Insider</a> 
            <a href="https://www.101obex.com/#comp-jxad0a3m" className="link link-header">Contact Us</a>
          </Col>
        </Row>
      </div>
    </Row>
  );
}

export default PricingMenu;