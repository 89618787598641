const regPassword = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,20}$/;

export const validatePass = (pass) => {
  if (
    !regPassword.test(pass) || 
    !validateNoRepeatCharacters(pass) ||
    !validateConsecutiveNumbers(pass)
  ) return false;
  return true;
}

const validateNoRepeatCharacters = (value) => {
  for (let i=0; i < value.length-2; i++){
      if ((value[i] == value[i+1])&&(value[i] == value[i+2])){
        return false;
      }
  }
  return true;
}

const validateConsecutiveNumbers = (value) => {
  const re = /^\d$/;
  for (let i=0; i < value.length-2; i++){
      if ((re.test(value[i])) && (re.test(value[i+1])) && (re.test(value[i+2]))){
          if ((value[i] == value[i+1]-1) && (value[i] == value[i+2]-2)){
              return false;
          }
      }
  }
  return true
}

export const validateRepeatPass = (pass, repeatPass) => {
  if (pass === repeatPass){
    return true;
  }
  return false;
}