import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col,  Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import PaymentConfirmationUpgradeModal from '../Modals/paymentConfirmationUpgrade';
import PaymentSuccessModal from '../Modals/paymentSuccess';
import SessionService from '../../services/SessionsService';


type AddonsProps = {
  show: boolean,
  handleClose: any,
  nameProject: string,
  planID: number,
  priceMonthly: number,
  priceYear: number,
  upgrade: boolean,
  seTstateToSend: (stateToSend) => void,
}

const AddonsModal:FunctionComponent<AddonsProps> = (props: AddonsProps) => {

  const { show, handleClose, nameProject, planID, priceMonthly, priceYear, upgrade, seTstateToSend } = props;


  const [pricePlanMontly, setPricePlanMontly] = useState(priceMonthly);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [projecstPlanInfo, setProjecstPlanInfo] = useState([]);
  const [requestsPlanInfo, setRequestsPlanInfo] = useState([]);
  const [requestsPlanInfo2, setRequestsPlanInfo2] = useState([]);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlanPriceMonthly, setProjectPriceMonthly] = useState(0);
  const [projectPlanPriceYear, setProjectPriceYear] = useState(0);
  const [txPlanPriceMonthly, setTxPriceMonthly] = useState(0);
  const [txPlanPriceYear, setTxPriceYear] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  const [pagoAhora, setPagoAhora] = useState(false);
  const [SuccesPayment, setSuccesPayment] =useState(false);


  const history = useHistory();

  const selectProject = (id, priceMonth, priceYear, desc) => {
    setProject(id);
    setProjectDesc(desc);
    setProjectPriceMonthly(priceMonth);
    setProjectPriceYear(priceYear);
  } 

  const selectTx = (id, priceMonth, priceYear, desc) => {
    setTx(id);
    setTxDesc(desc);
    setTxPriceMonthly(priceMonth);
    setTxPriceYear(priceYear);
  }

  const removeProject = (price) => {
    setProject(0);
    setProjectPriceMonthly(0);
  }

  const removeTx = () => {
    setTx(0);
    setTxPriceMonthly(0);
    setTxPriceYear(0);
  }

  const handleCloseP = async () => {

    setPagoAhora(false);
    setSuccesPayment(true);
    handleClose();

  };

  const handleSubmit = (event) => {

    if (!upgrade){
     const stateToSend = { 
      extraRequestsPlan: selectedTx,
      extraProjectsPlan: selectedProject,
      planID: planID,
      totalPriceMonthly: Number((pricePlanMontly + projectPlanPriceMonthly + txPlanPriceMonthly).toFixed(2)),
      pricePlanMonthly: pricePlanMontly,
      totalPriceYear:  Number((priceYear + projectPlanPriceYear + txPlanPriceYear).toFixed(2)),
      pricePlanYear: priceYear,
      nameProject: nameProject,
      projectPriceMonthly: projectPlanPriceMonthly,
      projectPriceYear: projectPlanPriceYear,
      txPriceMonthly: txPlanPriceMonthly,
      txPriceYear: txPlanPriceYear,
      txDesc: txDesc,
      projectDesc: projectDesc,


    }
    console.log('state to sneddd ', stateToSend)
    seTstateToSend(stateToSend)
    /*
    event.preventDefault();
    event.stopPropagation();
    history.push({
      pathname: '/signup',
      state: stateToSend
    })*/

  } else {

    setPagoAhora(true);
  }
  };

  const getPlans = async () => {
    try {
      const projectsPlans = (await ObexRequestHandler.get('/extraprojects/plans')).data;
      const requestsPlans = (await ObexRequestHandler.get('/extrarequests/plans')).data;
      // TODO ver si mostrar todos de momento solo el primero
      setProjecstPlanInfo([projectsPlans[0]]);
      setRequestsPlanInfo([requestsPlans[0]]);
      setRequestsPlanInfo2([requestsPlans[1]]);
    } catch (error) {
      console.error('ERROR FETCHING PLANS ', error);
    }
  }

  const projectsAddons = projecstPlanInfo.map(p => <Row 
    key={'addonmodal'+p.title+Math.random()}  
    className={'addon addons-border py-3 px-4 mb-4 position-relative' +
      (selectedProject === p.id && ' addons-active')}>
      {selectedProject === p.id && <FontAwesomeIcon icon={faCheck} />}
        <Col md="12" className="addons-title">
          <span>{p.title}</span>
        </Col>
        <Col>
          <Row>
            <Col md="6">
              <span>{p.description}</span>
            </Col>
            <Col className="price-addons">
            <span>{`$${p.monthly_price.toFixed(2)}/mo*`}</span>
            </Col>
            <Col>
              {selectedProject === p.id ? 
                <Button className="btn-block btn-remove-addons" onClick={removeProject}>Remove</Button>:
                <Button className={`btn-block btn-add ${buttonDisabled ? 'btn-ligth-gray': 'btn-add-green'}`} 
                  disabled={buttonDisabled} 
                  onClick={() => selectProject(p.id, p.monthly_price, p.annual_price, p.title)}  
                >
                  Add
                </Button>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      ) 

  const requestsAddons = requestsPlanInfo.map(r => <Row 
    key={'requestsAddons'+r.id+Math.random()}
      className={'addon addons-border py-3 px-4 mb-4 position-relative' +
      (selectedTx === r.id && ' addons-active')}>
      {selectedTx === r.id && <FontAwesomeIcon icon={faCheck} />}
        <Col md="12" className="addons-title">
          <span>{r.title}</span>
        </Col>
        <Col>
          <Row>
            <Col md="6">
              <span>{r.description}</span>
            </Col>
            <Col className="price-addons">
            <span>{`$${r.monthly_price.toFixed(2)}/mo*`}</span>
            </Col>
            <Col>
              {selectedTx === r.id ? 
                <Button className="btn-block btn-remove-addons" onClick={removeTx}>Remove</Button>:
                <Button className={`btn-block btn-add ${buttonDisabled ? 'btn-ligth-gray': 'btn-add-green'}`} 
                  disabled={buttonDisabled} 
                  onClick={() => selectTx(r.id, r.monthly_price, r.annual_price, r.title)}
                >
                  Add
                </Button>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      )

      const request2sAddons = requestsPlanInfo2.map(r => <Row 
        key={'request2sAddons'+r.id+Math.random()}
        className={'addon addons-border py-3 px-4 mb-4 position-relative' +
        (selectedTx === r.id && ' addons-active')}>
        {selectedTx === r.id && <FontAwesomeIcon icon={faCheck} />}
          <Col md="12" className="addons-title">
            <span>{r.title}</span>
          </Col>
          <Col>
            <Row>
              <Col md="6">
                <span>{r.description}</span>
              </Col>
              <Col className="price-addons">
              <span>{`$${r.monthly_price.toFixed(2)}/mo*`}</span>
              </Col>
              <Col>
                {selectedTx === r.id ? 
                  <Button className="btn-block btn-remove-addons" onClick={removeTx}>Remove</Button>:
                  <Button className={`btn-block btn-add ${buttonDisabled ? 'btn-ligth-gray': 'btn-add-green'}`} 
                    disabled={buttonDisabled} 
                    onClick={() => selectTx(r.id, r.monthly_price, r.annual_price, r.title)}
                  >
                    Add
                  </Button>
                }
              </Col>
            </Row>
          </Col>
        </Row>
        )

  useEffect(() => {
    getPlans();
  //  if (nameProject != 'Developer'){
  //    setButtonDisabled(false);
  //  }
  }, [])
  
  return(
    <><Modal dialogClassName="addons-dialog w-75 obex-dialog py-3 obex-dialog"
      show={show}
      onHide={handleClose}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0 pt-4">
        <Modal.Title className="text-center w-100">
          <Row>
            <Col className="addons-title">
              <span>Select your Add-ons</span>
            </Col>
          </Row>
          <Row>
            <Col md="10" className="mx-auto text-center modal-subtitle">
              <span className="text-center d-block">Select your add-ons and continue to checkout.</span>
              <span className="text-center d-block">Not sure? You can add-on at a later time.</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-4">
        <Container fluid>
          <Row className="mb-4">
            <Col className="addons-border px-4 py-2 d-flex flex-column mr-4">
              <Row className="plan-selected my-auto">
                <Col className="align-middle py-1 border-right">
                  Select Plan
                </Col>
                <Col className="plan-title border-left">
                  <span>{nameProject}</span>
                </Col>
              </Row>
            </Col>
            <Col className="addons-border px-4 py-2 text-center">
              <Row>
                <Col md="7" className="my-auto">
                  <span>Estimated monthly cost:</span>
                </Col>
                <Col className="text-right price-plan">
                  <span>${(pricePlanMontly + projectPlanPriceMonthly + txPlanPriceMonthly).toFixed(2)}*</span>
                </Col>
              </Row>
            </Col>
          </Row>

          {nameProject!='Developer' ?

          
            <span>          {projectsAddons}
              {requestsAddons}</span>
          :
         
            <span>          {requestsAddons}
              {request2sAddons}</span>

          }



          <Row className="mb-2">
            <Col className="text-center">

              {(selectedProject !== 0 || selectedTx !== 0) ?
                <Button className="btn-submit-addons with-add" onClick={handleSubmit}>Continue to Checkout</Button> :
                <Button className="btn-submit-addons" onClick={handleSubmit}>Continue Without Add-ons</Button>}

            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <span>*Taxes and fees may apply</span>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>

    {upgrade &&
    <><Modal 
          show={pagoAhora}
          onHide={handleClose}
        >
          <PaymentConfirmationUpgradeModal
            actionSubmit={handleCloseP}
            handleGoBack={()=>{setPagoAhora(false);}}
            nameServiceSelected={'Upgrade to plan ' + nameProject}
            amountMonth={priceMonthly}
            amountYear={priceYear * 10}
            newSuscriptionID={planID}
            processingPayment={null}
            success={null}
            error={null} />
        </Modal>
        <Modal
         show={SuccesPayment}>
          <PaymentSuccessModal
          paymentDescription={'Yor payment was successfull'} paymentType={'Now your plan has been updated.'}
          onCloseAction={()=>{

            // SessionService.renewSession('/dashboard');
            history.push('/dashboard');
            
            }}
          >

          </PaymentSuccessModal>
          </Modal>

          </>
    }
  
    </>

  );
};

export default AddonsModal;