import React, { FunctionComponent, useEffect, useState, Fragment   } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Button, Form, Card, Container, Spinner, Modal } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcAmex, faCcDinersClub, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { amountToCoin, parseToCoin } from '../../services/AmountService';
import SessionService from '../../services/SessionsService';
import PaymentSuccess from './paymentSuccess';
import PaymentConfirmation from '../Modals/paymentConfirmation';
import { faCheck } from '@fortawesome/free-solid-svg-icons';


type changePlanProps = {
  sider: any
}

const ChangePlanDurationContent:FunctionComponent<changePlanProps> = (props: changePlanProps) => {

  const [paymentMethod, setPaymentMethod] = useState('');
  const [aliasPayment, setAliasPayment] = useState('');
  const [typeMethod, setTypeMethod] = useState('');
  const [fetchingCard, setFetchingCard] = useState(false);
  const [PlanName, setPlanName] = useState('');
  const [PlanPriceYear, setPlanPriceYear] = useState('');
  const [PlanPriceMonth, setPlanPriceMonth] = useState('');
  const [AnualPayment, setAnualPaymeny] = useState(false);
  const [TaxAmount, setTaxAmount] = useState('');
  const [Loading, setLoading] = useState(false);
  const [paymentEnable, setPaymentEnable] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [PayToChange, setPaytoChange] = useState(0.0);

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);


let inputPayout= 0;
let autoRechargePayoutPerBalance = 0;
  const inputPayoutIntroduced = () => inputPayout > 0;
  const autoRechargeIntroduce = () => autoRechargePayoutPerBalance > 0;


  const modalBodyWhenProjectSelected =  <Modal.Body>
  <Container fluid>
    <Form className="obex-form">
      <Row className={'addon addons-border py-3 px-4 mb-4 position-relative' +
      (inputPayoutIntroduced() && ' addons-active')}>
      {inputPayoutIntroduced() && <FontAwesomeIcon icon={faCheck} />}
        <Col md="8">
          <Row>
            <Col md="12" className="addons-title">
              <span>Amount on EUR to add</span>
            </Col>
            <Col md="12">
              <span></span>
            </Col>
          </Row>
        </Col>
        <Col md="4" className="my-auto">
          <Form.Row>
            <Col className="">
              <Form.Control required type="number" placeholder="Amount" onChange={null}/>
            </Col>
          </Form.Row>
        </Col>
      </Row>

      <Form.Row className="mb-2">
        <Col className="text-center">
          {true ? 
          <Button disabled={Loading} className="btn-submit-addons with-add" onClick={() => {/*setPaymentEnable(true)*/}}>
            {Loading && <Fragment>&nbsp; <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/> </Fragment>}
            Continue to Checkout
          </Button> :
          <Button className="btn-submit-addons">Introduce a value</Button>}
        </Col>
      </Form.Row>

      <Row>
        <Col className="text-center">
          <span>* You'll receive {amountToCoin(inputPayout*1)} USD on your plugin, Taxes and fees may apply</span>
        </Col>
      </Row>

    </Form>
  </Container>
  </Modal.Body>


const handleSubmit = async (e) => {
  try {
    e.preventDefault();
    e.stopPropagation();
    changeDuration();
    setShow(false);
    setPaymentEnable(false);
  } catch {}
}



  const handleExit = () => {
    setPaymentSuccess(false);
   // onCloseModal();
  }

  const icons = {
    visa: faCcVisa,
    amex: faCcAmex, 
    dinners: faCcDinersClub, 
    mastercard: faCcMastercard
  }

  const changeDuration = async () =>{
    const payload = {
        annual: !AnualPayment,
        proRateAmount: PayToChange
    }
    setLoading(true);

    const result = await ObexRequestHandler.put('/subscriptions_payment', payload);
      const { success } = result;
      if (success){
        getSuscription();
      }
      SessionService.renewSession();
      setLoading(false);
  }

  const getSuscription = async () => {
    setLoading(true);
    const result = await ObexRequestHandler.get('/purchased_services', {}, true);
      const { success, data } = result;
      if (success){

        setPlanName(data.subscription.name);
        setAnualPaymeny(data.subscription.annual_payment);
        setPaytoChange(data.subscription.annual_price*10);
        // console.log('PRECIOS')
        // console.log(data.subscription.annual_price*10)
        let price ;

        price = parseToCoin((data.subscription.annual_price*10).toString());
        setPlanPriceYear(price);    
        price = parseToCoin((data.subscription.monthly_price).toString());
        setPlanPriceMonth(parseToCoin(data.subscription.monthly_price));
 
        if (data.subscription.annual_payment) {setTaxAmount(parseToCoin((data.subscription.annual_price*10)*0.21))} 
        else {setTaxAmount(parseToCoin(data.subscription.monthly_price*0.21));}

      }
      setLoading(false);
  }

  const getCards = async () => {
    try {
      setFetchingCard(true);
      // console.log('RECUPERANDO TARJETAS 3');
      /*
      const result = (await ObexRequestHandler.get('/cards')).data || {};
      const { defaultCard, cards } = result;
      if (cards) {
        const cd = cards.find(c => c.id === defaultCard);
        setTypeMethod(cd.brand.toLowerCase());
        setPaymentMethod(`...${cd.last4}`);
        setAliasPayment(cd.alias);
      }
      */

      setTypeMethod(SessionService.StripeDefaultCard.brand);
      setPaymentMethod(`...${SessionService.StripeDefaultCard.last4}`);
      const aliasPayment = SessionService.StripeDefaultCard.alias === undefined || SessionService.StripeDefaultCard.alias === 'undefined' ? SessionService.StripeDefaultCard.alias.replace('undefined', '') : SessionService.StripeDefaultCard.alias;
      console.log(aliasPayment);
      setAliasPayment(aliasPayment);

      
    } catch (error) {
      console.error('ERRRO FETCHING CARDS ', error);
    }
    setFetchingCard(false);
  }

  useEffect(() => {
    getCards();
    getSuscription();
  }, [])

  return(
    <DashLayout sider={props.sider} active={'plan_subcription'}>
                    {Loading ?

<Container className="vh-100 d-flex flex-column vw-100"> 
<Row className="h-100" >

            
          <Col md="1" className="mx-auto d-flex align-items-center">
            <Spinner animation="border"/>
          </Col>

        </Row>
        </Container>
          :

       <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Payment Duration</h1>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card className="obex-card product-detail-card">
                <Card.Body>
                  <Form className="obex-form payment-duration-form">
                    <Form.Row className="mb-3">
                      <Form.Label column sm="12" className="obex-form-label">
                        Plan name
                      </Form.Label>
                      <Col md="12">
                        <Form.Control required type="text" value={PlanName} placeholder="" readOnly/>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-2">
                      <Form.Label column sm="6" className="obex-form-label">
                        Payment change
                      </Form.Label>
                      <Form.Label column sm="6" className="obex-form-label">
                        Actual amount to be paid
                      </Form.Label>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Col md="6">
                        {AnualPayment ?
                        <Form.Control required type="text" value="Yearly to monthly" placeholder="" readOnly/>:
                        <Form.Control required type="text" value="Monthly to yearly" placeholder="" readOnly/>
                        }
                      </Col>
                      <Col md="6">
                        
                      {AnualPayment ?
                        <Form.Control required type="text" value={PlanPriceYear} placeholder="" readOnly/>:
                        <Form.Control required type="text" value={PlanPriceMonth} placeholder="" readOnly/>
                      }
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-2">
                      <Form.Label column sm="6" className="obex-form-label">
                        Tax
                      </Form.Label>
                      <Form.Label column sm="6" className="obex-form-label">
                        Amount to be paid now
                      </Form.Label>
                      <Col md="6">
                        <Form.Control required type="text" value={TaxAmount} placeholder="" readOnly/>
                      </Col>
                      <Col md="6">
                      {AnualPayment ?
                        <Form.Control required type="text" value={PlanPriceMonth} placeholder="" readOnly/>:
                        <Form.Control required type="text" value={PlanPriceYear} placeholder="" readOnly/>
                      }
                      </Col>
                    </Form.Row>
                    <Row>
                      <Col md="12">
                        <small className="d-block">* Tax mentioned may vary. The final tax amount will be reflected in your invoice.</small>
                        <small className="d-block">* Amount calculated on pro rata basis</small>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  <Row>
                    <Col>
                      <Card.Title>
                        <Row>
                          <Col><span className="title-product">Payment Method</span></Col>
                        </Row>
                      </Card.Title>
                      <div className="border-top my-2"></div>
                      <Card.Text>
                        <Row>
                          <Col md="6">
                            <span>
                              <FontAwesomeIcon icon={icons[typeMethod]} className="mr-2"/>
                              {paymentMethod}
                            </span>
                          </Col>
                          <Col md="6" className="text-right my-auto">
                            <span><span className="product-price">{aliasPayment}</span></span>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Col>
                    <Col md="4" className="mt-auto mb-3">
                      
                      {AnualPayment ?
                      <Button onClick={changeDuration} className="btn-block btn-green">Change Duration</Button>:
                      <Button onClick={()=>{setPaymentEnable(true);setShow(true)}} className="btn-block btn-green">Make Payment</Button>
                      }

                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
}


<Modal dialogClassName="addons-dialog w-75"
      show={show} 
      onHide={() => { setShow(false); setPaymentEnable(false);}}
      onClose={null}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center w-100">
          <Row>
            <Col className="addons-title">
            <span>Change Duration</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
        <span className="mx-auto m-3"><h5>Change your payment duration</h5></span>
   
      <div className="border-top border-black my-2 mx-3"></div>

      <Modal.Body>
        {paymentEnable && PayToChange>0 ? 
          <PaymentConfirmation
            actionSubmit={async ()=>{changeDuration(); setShow(false); setPaymentEnable(false)}}
            handleGoBack={() => { setShow(false); setPaymentEnable(false);}}
            nameServiceSelected={AnualPayment ? 'Yearly to monthly' : 'Monthly to yearly'}
            amountMonth={PayToChange}
            amountYear={PayToChange}
            processingPayment={Loading}
            success={success}
            error={error}
          />
          : (paymentSuccess ? <PaymentSuccess onCloseAction={handleExit} paymentDescription={''} paymentType={''}/> : <></>/*modalBodyWhenProjectSelected*/)
        }
      </Modal.Body>
    </Modal>



    </DashLayout>
    
  )
  
}

export default ChangePlanDurationContent;