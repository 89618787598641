import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import {  Row, Col, Modal, Button, Form, Alert, Spinner, Overlay, Tooltip } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';


type UpdateRateLimitModalProps = {
  project_name: string,
  project_id: string,
  maxAllowedTx: number,
  currentLimit: number,
  currentConsumed: number,
  shown: boolean,
  onCloseAction: () => void,
  onSuccessCreation: () => void
}

const UpdateRateLimitModal:FunctionComponent<UpdateRateLimitModalProps> = (props) => {
    const { shown, project_name, project_id, maxAllowedTx, currentLimit, currentConsumed, onCloseAction, onSuccessCreation } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [newRate, setNewRate] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleSubmit = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      try {
        const result = await ObexRequestHandler.put(`/projects/${project_id}/rate-limit`, { rate: newRate }) || {};
        const { success, data, message } = result;
        if (!success) throw { message };
        setSuccess(data);
        setTimeout(() => {
          if (!success.length) return; // Prevenimos que recargue de nuevo si cierra manualmente
          onSuccessCreation();
          setSuccess('');
        }, 2000)
      } catch (error) {
        console.error('error updating rate ', error)
        setError(error.message || error);
      }
      setLoading(false);
    }
    
    const handleNewRate = (e) => {
      const { value } = e.target;
      setNewRate(value);
      setSubmitDisabled(false);
    }

    const close = () => {
      if (success.length) onSuccessCreation();
      setSuccess('');
      setError('');
      onCloseAction();
    }

    useEffect(() => {
      setNewRate(currentLimit);
    }, [currentLimit])

    return (
      <Modal show={shown} onHide={close} dialogClassName="addons-dialog w-75 obex-dialog">
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title>
            <Row>
              <Col className="addons-title">
                <span >Updating {project_name}'s rate limiting.</span>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className="obex-form">
            <Form.Row className="mb-3">
              <Form.Label column sm="12" className="obex-form-label">
                New rate limit: {newRate}
              </Form.Label>
              <Col md="12">
                <Overlay target={target.current} show={show} placement="top">
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      {newRate}
                    </Tooltip>
                  )}
                </Overlay>
                <Form.Control type="range" ref={target} onClick={() => setShow(!show)} custom onChange={handleNewRate} value={newRate} max={maxAllowedTx} min={currentConsumed} step={1}/>
              </Col>
            </Form.Row>
            <Row>
              <Col md="4" className="mx-auto">
                <Button disabled={submitDisabled} className="btn-green btn-block obex-btn" onClick={handleSubmit}>
                  Update {success && <FontAwesomeIcon icon={faCheck}/>}
                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                </Button>
              </Col>
            </Row>
          </Form>
          {error.length > 0 && <Alert variant='danger'>{error}</Alert>}
        </Modal.Body>
      </Modal> 
    )
}

export default UpdateRateLimitModal;


