/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {  RouteProps, Route, Redirect } from 'react-router-dom';
import LoginForm from '../components/loginForm/LoginForm';
import LoginActivationForm from '../components/loginForm/LoginActivationForm';
import GetAuthorizationForm from '../components/loginForm/getAuthorization';
import React from 'react';
import SessionService from '../services/SessionsService';
import ContentDoc from '../components/ContentDoc/ContentDoc';
import DashboardContent from '../components/DashboardComponents/Dashboard';
import SingupForm from '../components/Signup/signup';
import PlansResume from '../components/PlansAndPricing/PlansResume';
import PageBlank from '../components/PageBlank/PageBlank';
import AccountVerified from '../components/AccountVerified/AccountVerified';
import VerifySignUp from '../components/Signup/VerifySignup';
import ProjectsContent from '../components/DashboardComponents/Projects';
import StagingProjects from '../components/DashboardComponents/stagingProjects';
import ProjectInfo from '../components/DashboardComponents/ProjectInfo';
import ProjectTransactionsInfo from '../components/DashboardComponents/ProjectTransactionsInfo';
import apisRouter from './routers/apis-router.json';
import downloadsRouter from './routers/downloads-router.json';
import docsRouter from './routers/docs-router.json';
import faqsRouter from './routers/faqs-routers.json';
import DashRouters from './routers/dashboard-routers.json';
import DevDashRouters from './routers/dev-dashboard-routers.json';
import ClientDashRouters from './routers/client-dashboard-routers.json';
import TransactionsContent from '../components/DashboardComponents/Transactions';
import ExchangeContent from '../components/DashboardComponents/Exchange';
import IncomeContent from '../components/DashboardComponents/Income';
import AccountInformationContent from '../components/DashboardComponents/SettingComponents/AccountInformation';
import TaxDetailsContent from '../components/DashboardComponents/SettingComponents/TaxInformation';
import PaymentMethodContent from '../components/DashboardComponents/SettingComponents/PaymentMethods';
import PublicPage from '../components/publicPage/PublicPage';
import ChangePassword from '../components/changeRecovery/changePassword';
import BalanceContent from '../components/DashboardComponents/BalanceComponents/BalanceContent';
import PaymentsContent from '../components/DashboardComponents/PaymentsComponents/PaymentsContent';
import CookiesManage from '../components/cookiesManage/cookiesManage';
import ChangePasswordSetting from '../components/DashboardComponents/SettingComponents/changePasswordSetting';
import ChangePlanDurationContent from '../components/PlanSubcriptionComponents/changePlanDuration';
import PlanSubscriptionContent from '../components/PlanSubcriptionComponents/PlanSubcription';
import PayoutsContent from '../components/DashboardComponents/SettingComponents/payouts';
import LogsContent from '../components/Developers/Logs';
import Sandbox from '../components/Developers/Sandbox';
import ApiKeysContent from '../components/Developers/apiKeys';
import CreateSubcriptionContent from '../components/DashboardComponents/AdminComponents/createSubcription';
import CommissionContent from '../components/DashboardComponents/Commissions';
import ProjectCurrencies from '../components/DashboardComponents/Currencies';
import ProjectCurrenciesActivated from '../components/DashboardComponents/CurrenciesActivated';
import MarketPlaceProjects from '../components/marketPlaceComponents/MarketPlaceProjects';
import MarketPlaceContent from '../components/marketPlaceComponents/marketPlaceContent';
import BankAccountContent from '../components/DashboardComponents/SettingComponents/BankAccount';
import UpdatePlan from '../components/PlansAndPricing/UpdatePlan';
import DeveloperContent from '../components/DashboardComponents/Developer';
import CustomSignUp from '../components/Signup/customsignup';
import OrganizationTeams from '../components/DashboardComponents/DeveloperTeams';
import Organizations from '../components/DashboardComponents/Organizations';
import DevDashboardContent from '../components/DashboardComponents/DevDashboard';
import MyTeams from '../components/DashboardComponents/MyTeams';
import DevTransactionsContent from '../components/DashboardComponents/DevTransactions';
import DevOrganizations from '../components/DashboardComponents/DevOrganizations';
import DeveloperPersonalContent from '../components/DashboardComponents/MyDevData';
import DeveloperDownloadContent from '../components/DashboardComponents/MyDevDownload';
import DownloadDownloadContent from '../components/DashboardComponents/MyDownDownload';
import RepoDownloadContent from '../components/DashboardComponents/MyRepoDownload';
import DevProjectsContent from '../components/DashboardComponents/DevProjects';
import DevLogsContent from '../components/Developers/DevLogs';
import DevSandbox from '../components/Developers/DevSandbox';
import DevStagingProjects from '../components/DashboardComponents/DevstagingProjects';
import LoginOpenBankingForm from '../components/loginForm/LoginOpenBanking';
import Privileges from '../components/DashboardComponents/Privileges';
import AcceptInvitation from '../components/loginForm/AcceptInvitation';
import AcceptProduction from '../components/loginForm/Acceptproduction';
import ConfirmClient from '../components/loginForm/ConfirmClient';
import ClientDashboardContent from '../components/DashboardComponents/ClientDashboard';
import ClientProjectsContent from '../components/DashboardComponents/ClienttProjects';
import ClientProjectInfo from '../components/DashboardComponents/ClientProjectInfo';
import AcceptChangeProduction from '../components/loginForm/AcceptChangeproduction';
import ProjectDevInfo from '../components/DashboardComponents/ProjectDevInfo copy';
import DevAccountInformationContent from '../components/DashboardComponents/SettingComponents/DevAccountInformation';
import ClientAccountInformationContent from '../components/DashboardComponents/SettingComponents/ClientAccountInformation';
import ProjectDevInfoStaging from '../components/DashboardComponents/ProjectDevInfoStaging';
import ApiTestContent from '../components/Developers/apiTest';
import ApiTestResult from '../components/Developers/apiResult';
import ApiStatus from '../components/Developers/apiStatus';



const Private = () => {return <h1>PRIVADO</h1>};
const Public = () => {return <h1>PUBLICO</h1>};
const isAdmin = ():boolean => SessionService.isAdmin();
const isDev = ():boolean => SessionService.isDev();
/**
 * Este bloque crea un componente que usaremos para rutas privadas
 * es decir, todas aquellas que se necesite estar logueado previamente
 * el funcionamiente lo que hace es comprobar si está loguead manda a la ruta, 
 * en caso de que no redirecciona al login.
 */
export const PrivateRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
          1 > 2 ? ( // Comprobacion chorra(TOTAL NADIE USA ESTO)
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};


export const RootRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
          isAdmin() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

// Rutas a las que todo el mundo tiene acceso
export const publicRoutes:Array<RouteProps> = [
  {
    path: '/',
    component: () => <Redirect to="/docs/download/download_101cli" />
    ,
    exact: true
  },
  {
    path: '/downloads',
    component: () => <ContentDoc sider={downloadsRouter} doc="download_github" cat="download"/>,
    exact: true
  },
  {
    path: '/downloads/:cat/:name',
    component: props => <ContentDoc 
    sider={downloadsRouter} 
    doc={props.match.params.name}
    cat={props.match.params.cat}/>,
    exact: true
  },
  {
    path: '/apis',
    component: () => <ContentDoc sider={apisRouter} doc="Accesos_EN" cat="login"/>,
    exact: true
  },
  {
    path: '/apis/:cat/:name',
    component: props => <ContentDoc 
      sider={apisRouter} 
      doc={props.match.params.name}
      cat={props.match.params.cat}
      />,
    exact: true
  },
    {
    path: '/docs',
    component: () => <ContentDoc sider={docsRouter} doc="Currencies_FX_Exchange_EN" cat="login"/>,
    exact: true
  },
  {
    path: '/docs/:cat/:name',
    component: props => <ContentDoc 
      sider={docsRouter} 
      doc={props.match.params.name}
      cat={props.match.params.cat}
      />,
    exact: true
  },
  {
    path: '/faq',
    component: () => <ContentDoc sider={faqsRouter} doc="Currencies_FX_Exchange_EN" cat="login"/>,
    exact: true
  },
  {
    path: '/faq/:cat/:name',
    component: props => <ContentDoc 
      sider={faqsRouter} 
      doc={props.match.params.name}
      cat={props.match.params.cat}
      />,
    exact: true
  },

  {
    path: '/docs/in_process',
    component: () => <ContentDoc sider={docsRouter} doc="Ciphered_Nodes_EN" cat="login"/>,
    exact: true
  },
  {
    path: '/dashboard',
    component: () => (!isDev ? <LoginForm/> : <DashboardContent sider={DashRouters}/>),
    exact: true
  },
  {
    path: '/devdashboard',
    component: () => (!isDev ? <LoginForm/> : <DevDashboardContent sider={DevDashRouters}/>),
    exact: true
  },

  ,
  {
    path: '/clientdashboard',
    component: () => (!isDev ? <LoginForm/> : <ClientDashboardContent sider={ClientDashRouters}/>),
    exact: true
  },



  {
    path: '/login',
    component: () => <LoginForm/>,
    exact: true
  },

  {
    path: '/openbanking/:Token',
    component: props => <LoginOpenBankingForm Token={props.match.params.Token}/>,
    exact:true
  },

  {
    path: '/signup/1',
    component: () => {
      return (<SingupForm 
        extraProjectsPlan={0} 
        extraRequestsPlan={0} 
        planID={1}
        totalPriceMonthly={0}
        totalPriceYear={0}
        nameProject={'Developer'}
        pricePlanMonthly={0}
        pricePlanYear={0}
        txPriceMonthly={null}
        txPriceYear={null}
        projectPriceMonthly={null}
        projectPriceYear={null}
        projectDesc={null}
        txDesc={null}
      />)
  },
  exact:true
},
{
  path: '/signup/2',
  component: () => {
    return (<SingupForm 
      extraProjectsPlan={0} 
      extraRequestsPlan={0} 
      planID={2}
      totalPriceMonthly={50}
      totalPriceYear={50}
      nameProject={'Startup'}
      pricePlanMonthly={50}
      pricePlanYear={50}
      txPriceMonthly={null}
      txPriceYear={null}
      projectPriceMonthly={null}
      projectPriceYear={null}
      projectDesc={null}
      txDesc={null}
    />)
},
exact:true
},
{
  path: '/signup/3',
  component: () => {
    return (<SingupForm 
      extraProjectsPlan={0} 
      extraRequestsPlan={0} 
      planID={3}
      totalPriceMonthly={150}
      totalPriceYear={150}
      nameProject={'Business'}
      pricePlanMonthly={150}
      pricePlanYear={150}
      txPriceMonthly={null}
      txPriceYear={null}
      projectPriceMonthly={null}
      projectPriceYear={null}
      projectDesc={null}
      txDesc={null}
    />)
},
exact:true
},
  {
    path: '/signup',
    component: (state) => {
      return (<SingupForm 
        extraProjectsPlan={state.location.state.extraProjectsPlan} 
        extraRequestsPlan={state.location.state.extraRequestsPlan} 
        planID={state.location.state.planID}
        totalPriceMonthly={state.location.state.totalPriceMonthly}
        totalPriceYear={state.location.state.totalPriceYear}
        nameProject={state.location.state.nameProject}
        pricePlanMonthly={state.location.state.pricePlanMonthly}
        pricePlanYear={state.location.state.pricePlanYear}
        txPriceMonthly={state.location.state.txPriceMonthly}
        txPriceYear={state.location.state.txPriceYear}
        projectPriceMonthly={state.location.state.projectPriceMonthly}
        projectPriceYear={state.location.state.projectPriceYear}
        projectDesc={state.location.state.projectDesc}
        txDesc={state.location.state.txDesc}
      />)
  },
    exact: true
  },
  {
    path: '/plans',
    component: () => <PlansResume/>,
    exact:true
  },
  {
    path: '/plans/:planID',
    component: props => <PlansResume planID={props.match.params.planID}/>,
    exact:true
  },
  {
    path: '/customsignup/:Token',
    component: props => <CustomSignUp Token={props.match.params.Token}/>,
    exact:true
  },
  {
    path: '/get_authorization/:Token',
    component: props => <GetAuthorizationForm Token={props.match.params.Token}/>,
    exact:true
  },
  {
    path: '/accept_invitation/:Token',
    component: props => <AcceptInvitation Token={props.match.params.Token}/>,
    exact:true
  },
  {
    path: '/accept_production/:Token',
    component: props => <AcceptProduction Token={props.match.params.Token}/>,
    exact:true
  },
  {
    path: '/accept_change_production/:Token',
    component: props => <AcceptChangeProduction Token={props.match.params.Token}/>,
    exact:true
  },
  {
    path: '/confirm_client/:Token',
    component: props => <ConfirmClient Token={props.match.params.Token}/>,
    exact:true
  },
  {
    path: '/updateplans/:planID',
    component: props => <UpdatePlan planID={props.match.params.planID}/>,
    exact:true
  },
  {
    path: '/plan_subcription/upgrade_plan',
    component: () => <UpdatePlan/>,
    exact:true
  },
  {
    path: '/blank',
    component: () => <PageBlank/>,
    exact:true
  },
  {
    path: '/verify_account/:key',
    component: () => <AccountVerified/>,
    exact:true
  },
  {
    path: '/verify_signup',
    component: () => <VerifySignUp/>,
    exact:true
  },
  {
    path: '/projects',
    component: () => <ProjectsContent/>,
    exact:true
  },
  {
    path: '/dev_projects',
    component: () => <DevProjectsContent/>,
    exact:true
  },
  {
    path: '/client_projects',
    component: () => <ClientProjectsContent/>,
    exact:true
  },
  {
    path: '/client_projects/:project',
    component: (state) => <ClientProjectInfo 
    projects={getProjectsFromState(state)}
  />,
  exact: true
  },
  {
    path: '/projects/:project',
    component: (state) => <ProjectInfo 
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },
    {
    path: '/dev_projects/:project',
    component: (state) => <ProjectDevInfo 
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },
  {
    path: '/dev_sta_projects/:project',
    component: (state) => <ProjectDevInfoStaging 
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },
  {
    path: '/projects/:project/commissions',
    component: () => <CommissionContent/>,
    exact: true
  },
  {
    path: '/transactions',
    component: () => <TransactionsContent/>,
    exact:true
  },
  {
    path: '/transactions/:project',
    component: (state) => <ProjectTransactionsInfo 
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },
  
  {
    path: '/dev_transactions',
    component: () => <DevTransactionsContent/>,
    exact:true
  },
  {
    path: '/dev_transactions/:project',
    component: (state) => <ProjectTransactionsInfo 
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },

/*

  {
    path: '/openbanking/:Token',
    component: props => <LoginOpenBankingForm Token={props.match.params.Token}/>,
    exact:true
  },

*/

  {
    path: '/privileges/:developer_id',
    component: props => <Privileges developer_id={props.match.params.developer_id}/>,
    exact: true
  },
  
  {
    path: '/exchange',
    component: () => <ExchangeContent/>,
    exact: true
  },
  {
    path: '/exchange/:project',
    component: () => <ProjectCurrencies/>,
    exact: true
  },
  {
    path: '/projects/:project/exchange_activated/',
    component: () => <ProjectCurrenciesActivated/>,
    exact: true
  },
  {
    path: '/income',
    component: () => <IncomeContent/>,
    exact:true
  },
  {
    path: '/settings/account_information',
    component: () => <AccountInformationContent/>,
    exact:true
  },
  {
    path: '/settings/tax_details',
    component: () => <TaxDetailsContent/>,
    exact:true
  },
  {
    path: '/settings/payment_methods',
    component: () => <PaymentMethodContent/>,
    exact:true
  },
  {
    path: '/settings/change_password',
    component: () => <ChangePasswordSetting/>
  },
  {
    path: '/income/payouts',
    component: () => <PayoutsContent/>
  },
  {
    path: '/settings/bankaccount',
    component: () => <BankAccountContent/>
  },
  {
    path: '/settings',
    component: () => <AccountInformationContent/>,
  },
  {
    path: '/dev_settings',
    component: () => <DevAccountInformationContent/>,
  },
    {
    path: '/client_settings',
    component: () => <ClientAccountInformationContent/>,
  },
  {
    path: '/public',
    component: () => <PublicPage/>
  },
  {
    path: '/change_password/:token',
    component: () => <ChangePassword/>
  },
  {
    path: '/resend_activation/:token',
    component: () => <LoginActivationForm/>
  },
  {
    path: '/developers',
    component: () => <DeveloperContent/>,
    exact:true
  },
  {
    path: '/developers_personal',
    component: () => <DeveloperPersonalContent/>,
    exact:true
  },
  
  {
    path: '/teams',
    component: () => <OrganizationTeams/>,
    exact:true
  },
  {
    path: '/dev_teams',
    component: () => <MyTeams/>,
    exact:true
  },
  {
    path: '/organizations',
    component: () => <Organizations/>,
    exact:true
  },
  {
    path: '/dev_organizations',
    component: () => <DevOrganizations/>,
    exact:true
  }
  ,
  {
    path: '/developers_personal/logs',
    component: () => <DevLogsContent/>,
    exact:true
  },
  {
    path: '/developers_personal/sandbox',
    component: () => <DevSandbox/>,
    exact:true
  },
  {
    path: '/developers_personal/staging_projects',
    component: () => <DevStagingProjects/>,
    exact:true
  },

  ,
  {
    path: '/developers_downloads',
    component: () => <DeveloperDownloadContent/>,
    exact:true
  },
  {
    path: '/developers_downloads/downloads_downloads',
    component: () => <DownloadDownloadContent/>,
    exact:true
  },

  {
    path: '/developers_downloads/downloads_repo',
    component: () => <RepoDownloadContent/>,
    exact:true
  },
  {
  path:'/developers/api_test',
  component:() => <ApiTestContent/>,
  exact:true
  },
  {
  path:'/developers/api_result',
  component:() => <ApiTestResult/>,
  exact:true
  },
  {
    path:'/developers/api_status',
    component:() => <ApiStatus/>,
    exact:true
    },

  {
    path: '/developers/logs',
    component: () => <LogsContent/>,
    exact:true
  },
  
  {
    path: '/developers/sandbox',
    component: () => <Sandbox/>,
    exact:true
  },
  {
    path: '/developers/staging_projects',
    component: () => <StagingProjects/>,
    exact:true
  },
  {
    path: '/developers/apikeys',
    component: () => <ApiKeysContent/>
  },
  {
    path: '/marketplace/:project',
    component: () => <MarketPlaceContent sider={DashRouters}/>
  },
  {
    path: '/marketplace',
    component: () => <MarketPlaceProjects/>
  },
  {
    path: '/balance',
    component: () => <BalanceContent/>
  },
  {
    path: '/plan_subcription/payments',
    component: () => <PaymentsContent/>
  },
  {
    path: '/cookies_manage',
    component: () => <CookiesManage/>
  },
  {
    path: '/plan_subcription/change_payment_duration',
    component: () => <ChangePlanDurationContent sider={DashRouters}/>
  },
  {
    path: '/plan_subcription',
    component: () => <PlanSubscriptionContent sider={DashRouters}/>
  },
]

//{(props) => <Dashboard {...props} isAuthed={true} />}

// Rutas privadas, necesitas estar logueado
export const privateRoutes:Array<RouteProps> = [
  {
    path: '/private',
    component: Private,
    exact: true
  },
]



// Rutas de administrador, debe de estar logueado y ser admin TODO ver esto
export const adminRoutes:Array<RouteProps> = [
  {
    path: '/admin',
    component: () => <CreateSubcriptionContent/>
  },
  {
    path: '/admin/create_subscriptions',
    component: () => <CreateSubcriptionContent/>
  },
]



const getProjectsFromState = (state) => {
  if (!state) return false;
  const projects = (((state.location || {}).state || {}).state || {}).projects || false;
  return projects;
}