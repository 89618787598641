import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBars } from '@fortawesome/free-solid-svg-icons';
import { ReactSortable } from 'react-sortablejs';
import { parseToNumberFormat } from '../../../services/AmountService';

// TODO un poquito de refactor y sacar componentes a otros ficheros

type CategoryConsumptionCardProps = {
  name: string,
  count: number
}

type CategoriesConsumptionProps = {
  categoryStats: Array<CategoryConsumptionCardProps>
}


// Card genérica de stats
const CategoryConsumptionCard:FunctionComponent<CategoryConsumptionCardProps> = ({ name, count }) => {
  return ( 
    <Col md="3" className="border-left request pt-2 pb-4">
      <Row>
        <Col className="request-title pl-2">
          <span>{name}</span>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10 }} className="request-text pl-2">
          <span className="text-center">{`${count} Req`}</span>
        </Col>
      </Row>
    </Col>
  )
}

type AddCategoryConsumptionProps = {
  onEditOrder: () => void
}

// Card para editar las categorias y el modal onEditOrder es una función pasada por el componente que renderiza todas las cards para que cuando se edite se vuelva a ejecutar la obtención de los estados
const AddCategoryConsumptionCard:FunctionComponent<AddCategoryConsumptionProps> = (props) => {

  const { onEditOrder } = props;
 
  const [editing, setEdition] = useState(false);
  const [categories, setCategories] = useState([])
  // const [projectosCargados, setprojectosCargados] = useState(0);

  const safeAndHideModal = async () => {
    const newCats = [];
    for (let id = 0; id < categories.length; id++) {
      const { name, checked } = categories[id];
      const cat = {
        id,
        name,
        checked
      }
      newCats.push(cat);
    }
    const payload = { order: newCats };
    const allDisabled = (newCats.filter(c => c.checked)).length < 1;
    if (!allDisabled) {
      ObexRequestHandler.post('/custom_categories', payload);
      onEditOrder(); // TODO mejorar esto para que use los movementStats ya almacenados que no tenga que pedirlos de nuevo
    }
    setEdition(false);
  }

  const openEditionModal = async () => {
    await getCategories();
    setEdition(true)
  }

  const getCategories = async () => {
    try {
      let categories;
      const custom = (await ObexRequestHandler.get('/custom_categories', {}, true)).data || [];
      if (!custom.length) categories = (await ObexRequestHandler.get('/categories')).data || [];
      const whenNoCustom = ['Access', 'Checkout online', 'Domiciliations'];
      categories = custom.length ? custom : (categories.sort(sortByNameByNameProper)).map(c =>  {
        const { id, name } = c;
        const checked = whenNoCustom.includes(name);
        return { id, name, checked } 
      });
      setCategories(categories);
    } catch (error) {
      console.error('ERROR FETCHING CATS ', error)
    }
  }

  const sortByNameByNameProper = (a, b) => {
    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    return 0;
  }

  const isTheCategory = (category, id) => {
    return category.id === id;
  }

  const handleSwitch = (e, category, id) => {
    const index = categories.findIndex((category) => isTheCategory(category, id));
    const newCategories = [...categories];
    newCategories[index].checked = !newCategories[index].checked;
    setCategories(newCategories);
  }

  // TODO estilos fuera de inline
  const categoriesList = (
    <Form>
      <ReactSortable tag="div" list={categories} setList={setCategories}>
          {categories.map(c => (
            <Row key={c.id} className="my-1">
              <Col>
                <Card className="product-detail-card obex-card api-product-card">
                  <Card.Body className="py-3 px-4">
                    <Row>
                      <Col className="pl-2">
                        <FontAwesomeIcon icon={faBars} className="button-sort mr-2" size="1x"/>
                        <span className="title-product">{c.name}</span>
                      </Col>
                      <Col md="2" className="text-right">
                        <Form.Check 
                          checked={c.checked}
                          type="switch"
                          size={5}
                          id={`${c.id}`}
                          label=''
                          onChange={(e) => handleSwitch(e, c, c.id)}
                          className="obex-switch"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
     </ReactSortable>
    </Form>
  )

  const modal = (
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={editing} 
      onHide={safeAndHideModal}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col className="addons-title">
              <span>API Products</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
        <Row className="px-3">
          <Col>
            {categoriesList}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )

  return ( 
    <Col md="3" className="border-left request">
      <Row>
        <Col md="2" className="mt-1 pl-2">
          <FontAwesomeIcon icon={faPlusCircle} className="btn-request pt-1" size="2x" onClick={openEditionModal}/>
        </Col>
        <Col className="request-title pl-1 pt-2">
          <span>Edit</span>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 2 }} className="request-text pl-1">
          <span className="text-center">Add and show API products</span>
        </Col>
      </Row>
      {modal}
    </Col>
  )
}


// Componente que renderiza todas las cards
const CategoriesConsumption:FunctionComponent<{}> = () => {

  const [movementStats, setMovementStats] = useState([]);
  const movementsCards = movementStats.map((list, i) => 
  <Fragment key={i}>
      <Row className="Tx-stats Rq-stats border-top mb-2 pt-2">
        {list.map(e => <CategoryConsumptionCard key={e.name} name={e.name} count={e.count}/>)}
        {(movementStats.length - 1) == i &&
          <Fragment>
            {list.length < 4 &&
              <AddCategoryConsumptionCard onEditOrder={() => getConsumptionStats()}/> 
            }
          </Fragment>
        }
      </Row>
      {(movementStats.length - 1) == i &&
          <Fragment>
            {list.length == 4 &&
              <Row className="Tx-stats Rq-stats border-top mb-2 pt-2">
                <AddCategoryConsumptionCard onEditOrder={() => getConsumptionStats()}/> 
              </Row>
            }
          </Fragment>
        }
    </Fragment>
  );

  const getConsumptionStats = async () => {

    try {
      const projectos = await ObexRequestHandler.get('/projects');

      if (projectos.data.length>0) {
          const customOrder = (await ObexRequestHandler.get('/custom_categories', {}, true)).data || [];
          const movements = await ObexRequestHandler.get('/movements_stats');
          const { success, data, message } = movements;
          if (!success)  throw { message };
          const stats = data.stats || [];
          const movementsToShow = customOrder.length ? getCustomMovementsCards(stats, customOrder) : getThreeFirsts(stats);
          setMovementStats(divideArray(movementsToShow, 4));
        }
    } catch (error) {
      console.error('movements_stats error ', error)
    }
  }

  const getCustomMovementsCards = (movements: Array<CategoryConsumptionCardProps>, order:Array<any>):Array<CategoryConsumptionCardProps> => {
  
    const checked = order.filter(c => c.checked);
    const stats = checked.map(c => { 
      const { name } = c;
      const count = (movements.find(m => m.name === name)).count;
      return { name, count } 
    });
    return stats;
  }

  const divideArray = (list, size) => {
    const listTolists = [];
    for (let i=0; i<list.length; i+=size) {
      listTolists.push(list.slice(i, i+size));
    }
    return listTolists;
  }

  const getThreeFirsts = (movements: Array<CategoryConsumptionCardProps>):Array<CategoryConsumptionCardProps> => {
    const threeFirsts:Array<CategoryConsumptionCardProps> = [];
    for (let index = 0; index < 3; index++) {
      const element = movements[index];
      threeFirsts.push(element);
    }
    return threeFirsts;
  }

  useEffect(() => {
    getConsumptionStats();
    //const comsumptionInterval = setInterval(getConsumptionStats, 30000); // Cada 30seg
    return () => { 
      //clearInterval(comsumptionInterval) // El return en el use effect ejectuta la función dada cuando el componente se desmonta
    }
  }, []);

  return (
    <Fragment>
        {movementsCards}
    </Fragment>
    )
  }
  
export default CategoriesConsumption;



