import { window } from 'd3';
import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Col, Image, Button } from 'react-bootstrap';
import ficha_user from '../../assets/img/pastilla1.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
//import SupportModal from '../Modals/supportModal';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import SessionService from '../../services/SessionsService';


const cookies = new Cookies();
ReactGA.initialize('UA-170480071-2');

const PlansBanner:FunctionComponent<{}> = () => {

  const [miniMize, setMinimize ] = useState('false'); 
  const [isLoggedIn, setLogged] = useState(false);
  const [showBannerOrSupportButton, setShow] = useState(false);
  const [activedSupport, setActivedSupport] = useState(false);

  const checkForActivedSupport = async () => {
    try {
      const actived = (await ObexRequestHandler.get('/active_support_plan')).data;
      setActivedSupport(actived);
      if (actived && !isLoggedIn) {
        const supportScript = document.getElementById('ze-snippet') as HTMLScriptElement;

        if (!supportScript) { // si no existe el script
          const script = document.createElement('script');

          script.id = 'ze-snippet';
          script.src = 'https://static.zdassets.com/ekr/snippet.js?key=1d42ba61-1ecd-4371-bea3-6891df9e5892';
          script.async = true;
        
          document.body.appendChild(script);
        }
      }
      setShow(true);
    } catch (error) {
      console.error('ERROR GETTING ACTIVED SUPPORT ', error);
    }
  }

  useEffect(() => {
    //checkForActivedSupport();  
    
    const elem2 = document.getElementById('SUPPORT_TITLE');

    const user = cookies.get('101Obex');

    const external_banner = cookies.get('external_banner');

    if ('external_banner' in (cookies || {})){
      if (external_banner == 'true') {
        setMinimize('true'); 

      }
      else {

      }

    } else 
    {

      setMinimize('false');

    }

    if (user){
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) 
      {
        SessionService.renewSession();
      }
      setLogged(true);} else setLogged(false)

    return () => {
      try {        
        //const supportScript = document.getElementById('ze-snippet') as HTMLScriptElement;
        //supportScript.parentElement.removeChild(supportScript);
        //const supportButton = document.getElementById('launcher') as HTMLIFrameElement;
        //supportButton.parentElement.removeChild(supportButton);
      } catch (error) {
        console.error('error removing support button')
      }
    }
  }, []);


  return (
    <Fragment >
      { (!isLoggedIn) &&
        <Fragment>

        { miniMize == 'true' || cookies.get('external_banner') == 'true'?


          <div id="REGISTER"className="box-support mt-auto text-center " 
            style={{ position: 'fixed', bottom: 0, width: '289px', marginLeft:'-24px', height:'25px' }} >

<span id="REGISTER_TITLE" style={{ fontSize: '20px', color: 'white', cursor: 'pointer', visibility: 'unset' }}>Register Now</span>
            
          <span 
            onClick={()=>{
              
              const elem = document.getElementById('REGISTER');
              const elem2 = document.getElementById('logo_register');
              const elem1 = document.getElementById('REGISTER_TITLE') as HTMLDivElement;
  
              // console.log(elem.style.height);
               if (elem.style.height == '25px') 
               {

                 
               elem1.style.visibility = 'hidden'
               elem.style.height = '';
               elem2.style.visibility = 'true';
               cookies.remove('external_banner');
               cookies.set('external_banner', 'false');
             } else {
               elem.style.height = '25px'
               elem2.style.visibility = 'false';
               cookies.remove('external_banner');
               cookies.set('external_banner', 'true');
               elem1.style.visibility = 'visible'
             }


              return console.log('');}}
            style={{ fontSize: '20px', color: 'white', cursor: 'pointer', marginLeft: '60px', marginRight: '-60px', display: 'absolute' }}>-</span>
            
            
            <Col md="5" className="my-3 mx-auto">
              <Image id='logo_register' src={ficha_user}  fluid/>
            </Col>
            <Col md="12" className="title-box mb-3">
              <span>101OBeX<br></br></span>
              <span>Register Now</span>
            </Col>
            <Col md="12" className="text-box mb-3">
              <span>Get your plan.</span>
            </Col>
            <Col md="12" className="mb-3">
              <Button className="obex-btn btn-green" href="https://www.101obex.com/pricing">see Plans</Button>
            </Col> 
          </div>
:

<div id="REGISTER"className="box-support mt-auto text-center " style={{ position: 'fixed', bottom: 0, width: '289px', marginLeft:'-24px' }} >
<span id="REGISTER_TITLE" style={{ fontSize: '20px', color: 'white', cursor: 'pointer', visibility: 'hidden' }}>Register Now</span>

            <span 
              onClick={()=>{
                
                const elem = document.getElementById('REGISTER');
                const elem2 = document.getElementById('logo_register');
                const elem1 = document.getElementById('REGISTER_TITLE') as HTMLDivElement;
  
               // console.log(elem.style.height);
                if (elem.style.height == '25px') 
                {

                  
                elem1.style.visibility = 'hidden'
                elem.style.height = '';
                elem2.style.visibility = 'true';
                cookies.remove('external_banner');
                cookies.set('external_banner', 'false');
              } else {
                elem.style.height = '25px'
                elem2.style.visibility = 'false';
                cookies.remove('external_banner');
                cookies.set('external_banner', 'true');
                elem1.style.visibility = 'visible'
              }
  
  
                return console.log('Prueba');}}
              style={{  fontSize: '20px', color: 'white', cursor: 'pointer', marginLeft: '60px', marginRight: '-60px', display: 'absolute' }}>-</span>
              
              
              <Col md="5" className="my-3 mx-auto">
                <Image id='logo_register' src={ficha_user}  fluid/>
              </Col>
              <Col md="12" className="title-box mb-3">
                <span>101OBeX<br></br></span>
                <span>Register Now</span>
              </Col>
              <Col md="12" className="text-box mb-3">
                <span>Get your plan.</span>
              </Col>
              <Col md="12" className="mb-3">
                <Button className="obex-btn btn-green" href="https://www.101obex.com/pricing">see Plans</Button>
              </Col> 
            </div>

          }
        </Fragment>
            

      }
    </Fragment>
    
  )
};

export default PlansBanner;