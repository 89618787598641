import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import FetchingSpinner from '../../../components/basicComponents/loaderModal';
import ObexAlert from '../../../components/basicComponents/ObexAlerts';


const borderColors = ['#FF5733', '#8BC222', '#2299C2', 
                      '#C02AC7', '#C72A44', '#C7C52A', 
                      '#6D3B0F', '#073241', '#B2B684',
                      '#591303', '#032E59', '#585903', 
                      '#2F0359', '#E16FE8', '#B1E86F', 
                      '#E89D6F', '#84766E', '#0A6B88'];

const baseDataset = {
  fill: true,
  lineTension: 0.3,
  backgroundColor: 'rgba(225, 204,230, .3)',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: 'rgb(205, 130,1 58)',
  pointBackgroundColor: 'rgb(255, 255, 255)',
  pointBorderWidth: 10,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgb(0, 0, 0)',
  pointHoverBorderColor: 'rgba(220, 220, 220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

const options = { 
  responsive: true,
  scales: {
    yAxes: [{
      stacked: true,
      ticks: {
        min: 0,
        stepSize: 1
      }
    }]
  }
}

type TransactionsProps = {
    from: string,
    to: string,
    project: string
};

const TransactionsMediaStats:FunctionComponent<TransactionsProps> = ({ from, to, project }) => {

  const [chartData, setChartData] = useState({ datasets: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');

  const getStats = async () => {
    try {
      setLoading(true);
      const mediaParams = { from, to };
      const mediaStats = (await ObexRequestHandler.get(`/media_stats/${project}`, mediaParams)).data || {};
      const { days, stats } = mediaStats;
      const dayLabels = Object.keys(days);
      const chartInfo = { 
        labels: dayLabels,
        datasets: [],
      }; 
      const catLabels = Object.keys(stats);

      for (let i = 0; i < catLabels.length; i++) {
        const data = [];
        const label = catLabels[i];
        const borderColor = borderColors[i];
        for (let y = 0; y < dayLabels.length; y++) {
          const dayLabel = dayLabels[y];
          const day = days[dayLabel];
          const count = (day && day[label]) ? day[label] : 0;
          data.push(count);
        }
        chartInfo.datasets.push(Object.assign({ label, data, borderColor }, baseDataset));
      }
      setChartData(chartInfo);

    } catch (error) {
      console.error('ERROR FETCHING TRANSACTIONS BY PROJECT ', error);
      setError(error.message || error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!from || !to || !project) {
      setLoading(true);
      return;
    };
    getStats();
  }, [from, to, project]);

  return (
    <div>
      {loading && chartData.datasets.length > 0 ? 
        <FetchingSpinner/>
      :
        <Fragment>
          {chartData.datasets.length > 0 ? 
            <Line data={chartData} height={90} options={options}/>
            : <span></span>
          }
        </Fragment>
      }
    <ObexAlert type='error' msg={error}/>
    </div>
  );
};


export default TransactionsMediaStats;