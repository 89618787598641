import { Container, Row, Col } from 'react-bootstrap';
import React, { FunctionComponent, useEffect } from 'react';
import '../../../assets/css/baseLayout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderMenu from '../baseLayout/ClientHeaderMenu';
import DashboardSideBar from './DevDashboardSidebar';
import Footer from './FooterMenu';

type BaseProps = {
  sider: any,
  active?: string,
  sublinks?: Array<any>,
  projects?: Array<any>,
  children?: any
};

const ClientBaseLayout:FunctionComponent<BaseProps> = (props:BaseProps) => {
  const { sider, children, active, sublinks } = props;

  return (
    <Container fluid>
      <HeaderMenu/>
      <Row id="content-page">
        <DashboardSideBar sider={sider} active={active} sublinks={sublinks}/>
        <Col id="content" className="d-flex flex-column min-vh-100">
          <Row className="pt-4 pl-4 pr-4">
            <Col>
              {children}
            </Col>
          </Row>
          <Row id="footer-dashboard" className="justify-content-md-center footer-dashboard mt-auto">
            <Col>
              <Footer/>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default ClientBaseLayout;