import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/ClientDashboardLayout';
import { Row, Col,  } from 'react-bootstrap';
import ConsumptionChart from './GraphComponents/ConsumptionChart';
//import ConsumptionChart from './GraphComponents/LineChart2';
import Consumptions from './Consumption/Consumptions';
import ClientCategoriesConsumption from './Consumption/ClientCategoriesConsumption';

type DashProps = {
    sider: any
};


const ClientDashboardContent:FunctionComponent<DashProps> = ({ sider }) => {
  
  return (
    <DashLayout sider={sider}>
   {/*}   <Consumptions/> */}
      <Row className="">
        <Col className="mt-4 p-0">
  {/*        <ConsumptionChart/>*/}
        </Col>
      </Row>
      <ClientCategoriesConsumption/>
    </DashLayout>
  )
}

export default ClientDashboardContent;